import { ChangeEvent, useState } from 'react';
import { PartAutoNumbering } from 'shared/lib/types/couch/settings';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import {
  PartBuildItem,
  PartBuildRecordedItem as RecordedItem,
  RunPartBuildRecorded,
} from 'shared/lib/types/views/procedures';
import { Item } from '../lib/inventoryUtil';
import { Location } from '../types';
import FieldInputBuildSingleItemSerial from './FieldInputBuildSingleItemSerial';

interface ValidSerialNumberMap {
  [key: string]: boolean;
}

type FieldInputBuildItemsSerialProps = {
  part: Part;
  item: PartBuildItem;
  recorded?: RunPartBuildRecorded;
  isEnabled: boolean;
  teamId: string;
  existingSerialNumbers: string[];
  isAddedDuringRun: boolean;
  onAddItem?: (item: RecordedItem) => void;
  onRemoveItem?: (item: RecordedItem) => void;
  onRecordItemValuesChanged: (item: RecordedItem, values: RecordedItem) => void;
  onRecordErrorsChanged: (errorObj: { [key: string]: string }) => void;
  autoNumbering?: PartAutoNumbering;
  onAddSerial?: (itemId?: string, prefix?: string) => void;
  onClearSerial?: (itemId?: string) => void;
  checkedOutItems?: Item[];
};

const FieldInputBuildItemsSerial = ({
  part,
  item,
  recorded,
  isEnabled,
  teamId,
  existingSerialNumbers,
  isAddedDuringRun,
  onAddItem,
  onRemoveItem,
  onRecordItemValuesChanged,
  onRecordErrorsChanged,
  autoNumbering,
  onAddSerial,
  onClearSerial,
  checkedOutItems,
}: FieldInputBuildItemsSerialProps) => {
  const [duplicateSerialNumbersMap, setDuplicateSerialNumbersMap] = useState<ValidSerialNumberMap>({});
  const [existingSerialNumbersMap, setExistingSerialNumbersMap] = useState<ValidSerialNumberMap>({});

  let items = [item];

  if (!isAddedDuringRun && recorded?.items) {
    const itemsArray: Array<RecordedItem> = Object.values(recorded.items);
    const itemsForPart = itemsArray.filter((_item) => {
      return _item.part_id === part.id && _item.part_index === item.part_index;
    });
    if (itemsForPart.length > 0) {
      items = itemsForPart;
    }
  }

  const handleAddItem = () => onAddItem && onAddItem(item);

  const canRemoveItem = isAddedDuringRun || items.length > 1;

  const onChangePrefix = (_item: RecordedItem, prefix: string) => {
    if (prefix) {
      onAddSerial && onAddSerial(_item.id, prefix);
    } else {
      onClearSerial && onClearSerial(_item.id);
    }
  };

  const onChangeItem = (_item: RecordedItem, e: ChangeEvent<HTMLInputElement>) => {
    const serial = e.target.value;
    const foundDuplicate = checkIfDuplicateSerialNumberInRun(_item, serial);
    const foundExisting = checkIfExistingSerialNumber(_item, serial);

    if (foundDuplicate || foundExisting) {
      onRecordErrorsChanged({ serial: 'Invalid Serial #' });
    } else {
      onRecordErrorsChanged({});
    }

    const values = {
      ..._item,
      ...(isAddedDuringRun ? {} : recorded?.items[_item.id]),
      serial,
    };
    onRecordItemValuesChanged(_item, values);
  };

  const onChangeLocation = (_item: RecordedItem, location: Location) => {
    const values = {
      ..._item,
      ...(isAddedDuringRun ? {} : recorded?.items[_item.id]),
      location_id: location ? location.id : '',
    };
    onRecordItemValuesChanged(_item, values);
  };

  const onRevChange = (_item: RecordedItem, newRevLabel: string, newRevId?: string) => {
    const values = {
      ..._item,
      revision: newRevLabel,
      revision_id: newRevId,
    };
    onRecordItemValuesChanged(_item, values);
  };

  const checkIfDuplicateSerialNumberInRun = (_item: RecordedItem, updatedSerial: string): boolean => {
    if (!recorded || (!recorded.items && !recorded.added_items)) {
      setSerialNumberMapStatus(setDuplicateSerialNumbersMap, _item.id, false);
      return false;
    }

    const serialNumberCountMap = new Map<string, number>();
    serialNumberCountMap.set(updatedSerial, 1);
    const recordedItems = [...Object.values(recorded.items), ...(recorded.added_items ?? [])];
    for (const _recordedItem of recordedItems) {
      if (_recordedItem.serial && part.id === _recordedItem.part_id && _recordedItem.id !== _item.id) {
        serialNumberCountMap.set(_recordedItem.serial, (serialNumberCountMap.get(_recordedItem.serial) || 0) + 1);
      }
    }

    let foundDuplicate = false;
    for (const _recordedItem of recordedItems) {
      if (_recordedItem.serial && part.id === _recordedItem.part_id && _recordedItem.id !== _item.id) {
        const count = serialNumberCountMap.get(_recordedItem.serial) ?? 0;
        setSerialNumberMapStatus(setDuplicateSerialNumbersMap, _recordedItem.id, count > 1);
        if (count > 1) {
          foundDuplicate = true;
        }
      }
    }

    // set duplicate status for the current item using the just updated serial #
    setSerialNumberMapStatus(
      setDuplicateSerialNumbersMap,
      _item.id,
      (serialNumberCountMap.get(updatedSerial) ?? 0) > 1
    );
    return foundDuplicate;
  };

  const checkIfExistingSerialNumber = (_item: RecordedItem, updatedSerial: string): boolean => {
    // need to keep track of other items that may be matching existing serial #
    let prevExistingSerialNumber = false;
    for (const [key, value] of Object.entries(existingSerialNumbersMap)) {
      if (key !== _item.id && value) {
        prevExistingSerialNumber = true;
        break;
      }
    }

    const existingSerialNumberFound = existingSerialNumbers.some(
      (serial) => serial.toLowerCase().trim() === updatedSerial.toLowerCase().trim()
    );
    setSerialNumberMapStatus(setExistingSerialNumbersMap, _item.id, existingSerialNumberFound);
    return prevExistingSerialNumber || existingSerialNumberFound;
  };

  const setSerialNumberMapStatus = (setSerialNumberMap: (serialNumberMap) => void, itemId: string, status: boolean) => {
    setSerialNumberMap((prevMap: ValidSerialNumberMap) => {
      if (prevMap[itemId] === status) {
        return prevMap;
      }

      return {
        ...prevMap,
        [itemId]: status,
      };
    });
  };

  return (
    <>
      {items.map((_item) => (
        <FieldInputBuildSingleItemSerial
          key={_item.id}
          part={part}
          item={_item}
          recordedItem={isAddedDuringRun ? _item : recorded?.items[_item.id]}
          isEnabled={isEnabled}
          teamId={teamId}
          onChangePrefix={(prefix) => onChangePrefix(_item, prefix)}
          onChangeItem={(e) => onChangeItem(_item, e)}
          onChangeLocation={(location) => onChangeLocation(_item, location)}
          canRemoveItem={canRemoveItem}
          onRemoveItem={() => onRemoveItem && onRemoveItem(_item)}
          isDuplicateSerialNumber={duplicateSerialNumbersMap[_item.id] || false}
          isExistingSerialNumber={existingSerialNumbersMap[_item.id] || false}
          autoNumbering={autoNumbering}
          onAddSerial={onAddSerial}
          onClearSerial={onClearSerial}
          onRecordErrorsChanged={onRecordErrorsChanged}
          checkedOutItems={checkedOutItems}
          onDuplicate={isEnabled && !isAddedDuringRun ? handleAddItem : undefined}
          onRevChange={(revisionLabel, revisionId) => onRevChange(_item, revisionLabel, revisionId)}
        />
      ))}
    </>
  );
};

export default FieldInputBuildItemsSerial;
