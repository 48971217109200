import { useCallback, useMemo } from 'react';
import { EntityType, ServerTag } from 'shared/lib/types/api/settings/tags/models';
import { DatabaseServices } from '../../../contexts/proceduresSlice';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { useTags } from '../../../hooks/useTags';
import useRealtimeUpdates, { useRealtimeUpdatesProps } from '../../../hooks/useRealtimeUpdates';
import { RealtimeData } from 'shared/lib/types/realtimeUpdatesTypes';
import realtime from '../../../lib/realtime';
import TagManager from './TagManager';

type EntityTagManagerProps = {
  entityId: string;
  entityType: EntityType;
  isDisabled?: boolean;
};

const EntityTagManager = ({ entityId, entityType, isDisabled = false }: EntityTagManagerProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { tags } = useTags();

  const getTags = useCallback(async () => {
    if (!services) {
      return;
    }
    return services.entityTags.getTagsForEntity(entityType, entityId);
  }, [entityId, entityType, services]);

  const { realtimeData: entityServerTags } = useRealtimeUpdates<RealtimeData & ServerTag>({
    entityType: 'entity_tags',
    initialGetter: getTags,
    mergeFunction: realtime.filterOriginalListAndMerge,
  } as useRealtimeUpdatesProps);

  const entityTags = useMemo(() => {
    return (entityServerTags as Array<RealtimeData & ServerTag>).map(
      ({ created_by, updated_by, created_at, updated_at, team_id, deleted_at, lower_name, ...tag }) => ({
        ...tag,
        createdBy: created_by,
        updatedBy: updated_by,
        createdAt: created_at,
        updatedAt: updated_at,
      })
    );
  }, [entityServerTags]);

  return (
    <TagManager
      entityTags={entityTags}
      globalTags={tags}
      entityId={entityId}
      entityType={entityType}
      isDisabled={isDisabled}
    />
  );
};

export default EntityTagManager;
