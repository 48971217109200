import { Project, Projects } from 'shared/lib/types/couch/settings';
import { FilterOption } from '../components/lib/SearchFilter';

export const DEFAULT_PROJECT_NAME = 'Default';

const projectUtil = {
  isProjectCodeUnique(
    project: Project,
    projectsMap?: Record<string, Project>
  ): boolean {
    if (!projectsMap) {
      return true;
    }

    const projectIds = Object.keys(projectsMap);

    if (!projectIds.length) {
      return true;
    }

    return !projectIds.some((projectId) => {
      // Ignore the same project.
      if (projectId === project.id) {
        return false;
      }

      const projectCompare = projectsMap[projectId];

      /**
       * Legacy project objects do not have the code property.
       * If the code property is undefined in either project or
       * the project that it is being compared with, return false.
       */
      if (!projectCompare.code || !project.code) {
        return false;
      }

      return projectCompare.code.toLowerCase() === project.code.toLowerCase();
    });
  },
  getProjectName(
    projects: Projects | null,
    projectId?: string | null
  ): string | undefined {
    if (!projectId) {
      return undefined;
    }

    if (!projects?.projects?.[projectId]) {
      return undefined;
    }

    return projects.projects[projectId].name;
  },

  getProjectNames(
    keys: ReadonlySet<string | null>,
    projects: Projects | null
  ): Set<string> {
    const projectNames: Set<string> = new Set<string>();
    keys.forEach((id) => {
      if (id === null) {
        projectNames.add('Unassigned');
      } else {
        if (projects && projects.projects && projects.projects[id]) {
          projectNames.add(projects.projects[id].name);
        }
      }
    });
    return projectNames;
  },

  getProjectCode(
    projects: Projects | null,
    projectId?: string
  ): string | undefined {
    if (!projectId) {
      return undefined;
    }
    return projects?.projects?.[projectId]?.code;
  },

  getProjectsAsOptions(projects: Projects | null): Array<FilterOption<string>> {
    return Object.values(projects?.projects ?? {}).map((project) => ({
      id: project.id,
      label: project.name,
    }));
  },

  getActiveParentProjects(projects: Projects | null): Project[] {
    if (!projects) return [];

    return Object.values(projects?.projects).filter(
      (project) => !project.parent_id && project.project_state === 'active'
    );
  },
};

export default projectUtil;
