import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActivityType } from 'shared/lib/comment';
import { ItemAction } from 'shared/lib/types/postgres/manufacturing/items';
import { WorkOrderAction } from 'shared/lib/types/postgres/manufacturing/work_orders';
import { OperationAction } from 'shared/lib/types/postgres/operations';
import Icon, { IconType } from '../../elements/Icon';
import { CheckInIcon, CheckOutIcon } from '../../elements/SVGIcons';
import { ExpandedItemAction } from '../../manufacturing/types';
import { checkLinkedRunStarted } from './Renderers/LinkedRunRenderer';

const handleActionType = (actionType: string): [string, IconType] => {
  if (checkLinkedRunStarted(actionType)) {
    return ['text-green-200', 'list-check'];
  }

  let color = 'text-blue-200';
  let icon: IconType = 'pencil';

  switch (actionType) {
    case 'Updated Severity ID':
    case 'Updated Status ID':
    case 'Updated Sub Status ID':
    case 'Updated Title':
    case 'Updated Project ID':
    case 'edit':
      break;
    case WorkOrderAction.UpdatedStatus:
    case ExpandedItemAction.AttachmentsUpdated:
    case ExpandedItemAction.ComponentsUpdated:
    case ExpandedItemAction.LocationUpdated:
    case ExpandedItemAction.NotesUpdated:
    case ExpandedItemAction.QuantityUpdated:
    case ExpandedItemAction.StatusUpdated:
    case ExpandedItemAction.UnitCostUpdated:
      color = 'text-purple-200';
      break;
    case ActivityType.Comment:
    case 'Added Comment':
      icon = 'comment';
      color = 'text-gray-200';
      break;
    case 'Updated Assignee':
    case WorkOrderAction.AssigneesAdded:
      icon = 'user';
      break;
    case WorkOrderAction.AssigneesRemoved:
      icon = 'user';
      color = 'text-red-200';
      break;
    case OperationAction.AssigneeUpdated:
      icon = 'user';
      color = 'text-purple-200';
      break;
    case 'Updated Notes':
      icon = 'clipboard';
      break;
    case OperationAction.DescriptionUpdated:
      icon = 'clipboard';
      color = 'text-purple-200';
      break;
    case 'Created Issue':
    case 'Created Risk':
    case 'create':
    case ItemAction.IssueLinked:
      icon = 'file-circle-exclamation';
      color = 'text-red-200';
      break;
    case 'pause':
      icon = 'pause';
      color = 'text-yellow-200';
      break;
    case 'resume':
    case OperationAction.Started:
      icon = 'play';
      color = 'text-green-200';
      break;
    case OperationAction.Ended:
      icon = 'stop';
      color = 'text-gray-200';
      break;
    case OperationAction.EventStarted:
      icon = 'play';
      color = 'text-blue-200';
      break;
    case OperationAction.EventEnded:
      icon = 'stop';
      color = 'text-blue-200';
      break;
    case 'issue pause':
      icon = 'pause';
      color = 'text-orange-200';
      break;
    case 'all issues resolved':
      icon = 'thumbs-up';
      break;
    case 'Updated Issue Actions':
    case 'Updated Risk Actions':
      icon = 'check';
      break;
    case 'Issue Action Signed Off':
    case 'Risk Action Signed Off':
      icon = 'check';
      color = 'text-green-200';
      break;
    case ItemAction.Created:
    case ItemAction.Updated:
    case OperationAction.Created:
      icon = 'save';
      color = 'text-orange-200';
      break;
    case ItemAction.Received:
      icon = 'box-open';
      color = 'text-orange-200';
      break;
    case ItemAction.CheckedIn:
      icon = CheckInIcon;
      color = 'text-blue-200';
      break;
    case ItemAction.CheckedOut:
      icon = CheckOutIcon;
      color = 'text-blue-200';
      break;
    case ItemAction.UsageRecorded:
      icon = 'spinner';
      color = 'text-yellow-200';
      break;
    case ItemAction.DetailUpdated:
      icon = 'pen-to-square';
      color = 'text-gray-200';
      break;
    case WorkOrderAction.RunLinked:
      icon = 'list-check';
      color = 'text-green-200';
      break;
    case OperationAction.EventAdded:
      icon = 'calendar-days';
      color = 'text-purple-200';
      break;
    case OperationAction.EventDeleted:
      icon = 'x';
      color = 'text-red-200';
      break;
    default:
      icon = 'file';
      break;
  }
  return [color, icon];
};

export interface TimelineIconProps {
  actionType: string;
  isFirstIcon?: boolean;
}

const TimelineIcon = ({ actionType, isFirstIcon = true }: TimelineIconProps) => {
  const [color, icon] = handleActionType(actionType);

  return (
    <div className="flex-none w-6 h-full">
      {/* Overlay a background-colored circle underneath the actual status icon circle so that the blue line does not show through */}
      {isFirstIcon && <div className="absolute ml-3 h-full border-l-2 border-gray-300 opacity-50" />}
      <FontAwesomeIcon icon="circle" className="absolute text-4xl text-white" />
      <FontAwesomeIcon icon="circle" className={`z-5 absolute mt-0.5 mr-1 text-3xl ${color}`} />
      <Icon element={icon} className="fa-fw ml-1 z-6 mt-2 absolute text-gray-600 text-sm" />
    </div>
  );
};

export default TimelineIcon;
