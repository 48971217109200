import { AxiosResponse } from 'axios';
import superlogin from '../api/superlogin';
import RunService from '../api/runs';
import ExternalDataService from '../api/externalData';
import TagService from '../api/tags';
import EntityTagService from '../api/entityTags';
import {
  addFullStepSuggestedEdit,
  addLinkedRun,
  addParticipant,
  addStep,
  addStepComment,
  addGlobalTag,
  clearOperation,
  completeStep,
  editSuggestedEditComment,
  endRun,
  failStep,
  includeFullStepSuggestedEdit,
  removeGlobalTag,
  removeParticipant,
  reopenRun,
  repeatSection,
  repeatStep,
  revokeStepSignoff,
  saveRedlineHeader,
  saveRedlineStepComment,
  setOperation,
  signOffStep,
  skipSection,
  skipStep,
  startRun,
  updateBlock,
  updateRunTags,
  updateStepDetail,
} from '../contexts/runsSlice';
import AttachmentService from '../attachments/service';
import runUtil from '../lib/runUtil';
import { getRedlineId } from 'shared/lib/redlineUtil';
import { EntityType } from 'shared/lib/types/api/settings/tags/models';
import { updateGlobalTag } from '../contexts/settingsSlice';

/**
 * This is the reconciler function used by redux-offline (the config.effect field).
 * This function will be called for every action dispatched with the meta.offline property.
 *
 * It is used to execute the network effects - so essentially our logic to
 * make backend calls should live here.
 *
 * This function will only be called if redux-offline determines the app is online.
 * When offline, the effects will be queued up and this will be called in the order
 * the original actions were dispatched.
 */
export const offlineEffects = (
  effect: unknown,
  action: { payload?; type }
): Promise<AxiosResponse | string | void> => {
  const session = superlogin.getSession();

  if (!session) {
    return Promise.reject('No active session');
  }

  const { teamId } = action.payload;

  if (!teamId) {
    return Promise.reject('No active team found');
  }

  /**
   * Create service clients.
   *
   * These effects run asynchronously outside of the auth flow, so we can't
   * easily get the service objects from the DatabaseContext.
   * TODO: Refactor these service objects somehow to avoid creating new ones.
   */
  const runs = new RunService(teamId);
  const externalData = new ExternalDataService(teamId);
  const tags = new TagService(teamId);
  const entityTags = new EntityTagService(teamId);

  switch (action.type) {
    case addLinkedRun.type: {
      const { run, sectionId, stepId, contentId, linkedRunId } = action.payload;
      return runs.addLinkedRun({
        runId: run._id,
        sectionId,
        stepId,
        contentId,
        linkedRunId,
      });
    }
    case addParticipant.type: {
      const { runId, createdAt } = action.payload;
      return runs.addParticipant(runId, createdAt);
    }
    case addStepComment.type: {
      const {
        runId,
        sectionId,
        stepId,
        contentId,
        rowIndex,
        columnIndex,
        comment,
      } = action.payload;

      const syncCommentAttachments = () => {
        const commentAttachmentIds: Array<string> = [];

        if (comment.attachment) {
          commentAttachmentIds.push(comment.attachment.attachment_id);
        }

        if (comment.attachments) {
          comment.attachments.forEach((attachment) => {
            commentAttachmentIds.push(attachment.attachment_id);
          });
        }

        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(commentAttachmentIds);
      };

      return syncCommentAttachments()
        .then(() => {
          return runs.addStepComment(runId, comment, {
            sectionId,
            stepId,
            contentId,
            rowIndex,
            columnIndex,
          });
        })
        .catch(() => {
          return Promise.reject();
        });
    }
    case endRun.type: {
      const { runId, recorded, comment, status, endedAt } = action.payload;
      return runs.endRun(runId, recorded, comment, status, endedAt);
    }
    case reopenRun.type: {
      const { run, comment, timestamp } = action.payload;
      return runs.reopenRun({ runId: run._id, comment, timestamp });
    }
    case removeParticipant.type: {
      const { runId, createdAt } = action.payload;
      return runs.removeParticipant(runId, createdAt);
    }
    case signOffStep.type: {
      const {
        runId,
        sectionId,
        stepId,
        signoffId,
        completedAt,
        operator,
        recorded,
      } = action.payload;

      const syncFieldInputAttachments = () => {
        const fieldInputAttachmentIds =
          runUtil.getFieldInputAttachmentIds(recorded);
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(fieldInputAttachmentIds);
      };

      return syncFieldInputAttachments().then(() =>
        runs.signOffStep(
          runId,
          sectionId,
          stepId,
          signoffId,
          completedAt,
          operator,
          recorded
        )
      );
    }
    case revokeStepSignoff.type: {
      const { runId, sectionId, stepId, signoffId, timestamp } = action.payload;

      return runs.revokeStepSignoff({
        runId,
        sectionId,
        stepId,
        signoffId,
        timestamp,
      });
    }
    case completeStep.type: {
      const { runId, sectionId, stepId, completedAt, recorded } =
        action.payload;

      const syncFieldInputAttachments = () => {
        const fieldInputAttachmentIds =
          runUtil.getFieldInputAttachmentIds(recorded);
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(fieldInputAttachmentIds);
      };

      return syncFieldInputAttachments().then(() =>
        runs.completeStep(runId, sectionId, stepId, completedAt, recorded)
      );
    }
    case failStep.type: {
      const { runId, sectionId, stepId, failedAt, recorded } = action.payload;

      const syncFieldInputAttachments = () => {
        const fieldInputAttachmentIds =
          runUtil.getFieldInputAttachmentIds(recorded);
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(fieldInputAttachmentIds);
      };

      return syncFieldInputAttachments().then(() =>
        runs.failStep(runId, sectionId, stepId, failedAt, recorded)
      );
    }
    case updateBlock.type: {
      const {
        runId,
        sectionId,
        stepId,
        contentId,
        actionId,
        recorded,
        timestamp,
        fieldIndex,
      } = action.payload;
      const syncFieldInputAttachments = () => {
        const attachmentIds = runUtil.getBlockRecordedAttachmentIds(recorded);
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(attachmentIds);
      };

      return syncFieldInputAttachments().then(() => {
        return runs.updateStepContent({
          runId,
          sectionId,
          stepId,
          contentId,
          actionId,
          recorded,
          timestamp,
          fieldIndex,
        });
      });
    }
    case updateStepDetail.type: {
      const { runId, sectionId, stepId, field, value } = action.payload;

      return runs.updateStepDetail({
        runId,
        sectionId,
        stepId,
        field,
        value,
      });
    }
    case startRun.type: {
      const { run } = action.payload;
      return externalData
        .updateExternalItems(run)
        .then((updated) => runs.startRun(updated))
        .catch(() => {
          // If updating external data failed, fallback to use original run.
          return runs.startRun(run);
        });
    }
    case skipStep.type: {
      const { run, userId, sectionId, stepId, skippedAt, recorded } =
        action.payload;
      return runs.skipStep(run, userId, sectionId, stepId, skippedAt, recorded);
    }
    case skipSection.type: {
      const { run, userId, sectionId, skippedAt, recordedTelemetrySection } =
        action.payload;
      return runs.skipSection(
        run,
        userId,
        sectionId,
        skippedAt,
        recordedTelemetrySection
      );
    }
    case repeatStep.type: {
      const {
        run,
        userId,
        recorded,
        stepRepeat,
        sectionId,
        stepId,
        includeRedlines,
      } = action.payload;
      return runs.repeatStep(
        run,
        userId,
        recorded,
        stepRepeat,
        sectionId,
        stepId,
        includeRedlines
      );
    }
    case repeatSection.type: {
      const {
        run,
        userId,
        recorded,
        sectionRepeatOptions,
        sectionId,
        includeRedlines,
      } = action.payload;
      return runs.repeatSection(
        run,
        userId,
        recorded,
        sectionRepeatOptions,
        sectionId,
        includeRedlines
      );
    }
    case setOperation.type: {
      const { runId, operation } = action.payload;
      return runs.setOperation(runId, operation.name);
    }
    case clearOperation.type: {
      const { runId } = action.payload;
      return runs.clearOperation(runId);
    }
    case updateRunTags.type: {
      const { runId, runTags } = action.payload;
      return runs.updateRunTags(runId, runTags);
    }
    case updateGlobalTag.type: {
      const { tag } = action.payload;
      return tags.upsertTag(tag);
    }
    case addGlobalTag.type: {
      const { runId, tagId } = action.payload;
      return entityTags.addTagToEntity(EntityType.RUN, runId, tagId);
    }
    case removeGlobalTag.type: {
      const { runId, tagId } = action.payload;
      return entityTags.removeTagFromEntity(EntityType.RUN, runId, tagId);
    }
    case saveRedlineStepComment.type: {
      const { runId, stepId, text, updatedAt, commentId } = action.payload;
      return runs.addRedlineStepComment({
        runId,
        stepId,
        text,
        updatedAt,
        commentId,
      });
    }
    case saveRedlineHeader.type: {
      const {
        run,
        header,
        pending,
        headerRedlineMetadata,
        isRedline,
        createdAt,
      } = action.payload;
      return runs.addRedlineHeader(
        run,
        header,
        pending,
        headerRedlineMetadata,
        isRedline,
        createdAt
      );
    }
    case addStep.type: {
      const { runId, sectionId, precedingStepId, step, createdAt, runOnly } =
        action.payload;

      const syncFieldInputAttachments = () => {
        const fieldInputAttachmentIds = step.content.flatMap((block) => {
          return runUtil.getFieldInputAttachmentIds(block.recorded);
        });
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(fieldInputAttachmentIds);
      };

      return syncFieldInputAttachments().then(() =>
        runs.addStep({
          runId,
          sectionId,
          precedingStepId,
          createdAt,
          step,
          runOnly,
        })
      );
    }
    case addFullStepSuggestedEdit.type: {
      const { runId, sectionId, stepId, redline, includeInRun } =
        action.payload;

      const syncFieldInputAttachments = () => {
        const fieldInputAttachmentIds = redline.step.content.flatMap(
          (block) => {
            return runUtil.getFieldInputAttachmentIds(block.recorded);
          }
        );
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(fieldInputAttachmentIds);
      };

      return syncFieldInputAttachments().then(() =>
        runs.addFullStepSuggestedEdit({
          runId,
          sectionId,
          stepId,
          redline,
          includeInRun,
        })
      );
    }
    case includeFullStepSuggestedEdit.type: {
      const { runId, sectionId, stepId, redline, includedAt } = action.payload;

      const syncFieldInputAttachments = () => {
        const fieldInputAttachmentIds = redline.step.content.flatMap(
          (block) => {
            return runUtil.getFieldInputAttachmentIds(block.recorded);
          }
        );
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(fieldInputAttachmentIds);
      };

      return syncFieldInputAttachments().then(() =>
        runs.includeFullStepSuggestedEdit({
          runId,
          sectionId,
          stepId,
          redlineId: getRedlineId(redline) ?? '',
          includedAt,
        })
      );
    }
    case editSuggestedEditComment.type: {
      const {
        runId,
        sectionId,
        stepId,
        redlineId,
        commentId,
        updatedText,
        updatedAt,
      } = action.payload;

      return runs.editSuggestedEditComment({
        runId,
        sectionId,
        stepId,
        redlineId,
        commentId,
        updatedText,
        updatedAt,
      });
    }
    default:
      return Promise.reject(`Unrecognized offline action ${action.type}`);
  }
};
