import { useCallback, useMemo } from 'react';
import { ServerTag, Tag } from 'shared/lib/types/api/settings/tags/models';
import { RealtimeData } from 'shared/lib/types/realtimeUpdatesTypes';
import { DatabaseServices } from '../contexts/proceduresSlice';
import useRealtimeUpdates, {
  useRealtimeUpdatesProps,
} from './useRealtimeUpdates';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import realtime from '../lib/realtime';

type UseTagsReturns = {
  tags: Tag[];
  isLoading: boolean;
};

export const useTags = (): UseTagsReturns => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const getInitialTags = useCallback(async () => {
    if (!services) {
      return;
    }
    return services.tags.getTags();
  }, [services]);

  const { realtimeData: serverTags, isLoading } = useRealtimeUpdates<
    RealtimeData & ServerTag
  >({
    entityType: 'global_tags',
    initialGetter: getInitialTags,
    mergeFunction: realtime.filterOriginalListAndMerge,
  } as useRealtimeUpdatesProps);

  const tags: Tag[] = useMemo(() => {
    return (serverTags as Array<RealtimeData & ServerTag>).map(
      ({
        created_by,
        updated_by,
        created_at,
        updated_at,
        team_id,
        deleted_at,
        lower_name,
        ...tag
      }) => ({
        ...tag,
        createdBy: created_by,
        updatedBy: updated_by,
        createdAt: created_at,
        updatedAt: updated_at,
      })
    );
  }, [serverTags]);

  return { tags, isLoading };
};
