import cloneDeep from 'lodash.clonedeep';
import {
  generateCommentId,
  generateRedlineDocId,
  generateRunId,
} from './idUtil';
import {
  getStepById,
  hasStep,
  isReleased,
  isSuggestEditsStepSettingEnabled,
  isTokenizedTextBlock,
  isRepeatStepSettingEnabled,
  isSkipStepSettingEnabled,
  getHeaderById,
} from './procedureUtil';
import {
  displaySectionStepKey as procedureSectionStepKey,
  displaySectionKey as procedureSectionKey,
} from './procedureUtil';
import signoffUtil, { SIGNOFF_ACTION_TYPE } from './signoffUtil';
import {
  Procedure,
  Recorded,
  RecordedValues,
  RepeatedSection,
  RepeatedSectionOrStep,
  RepeatedStep,
  Run,
  RunProcedureLinkBlock,
  RedlinedStep,
  RunAction,
  RunFieldInputRecordedValue,
  RunAddedStep,
  RunSection,
  RunStep,
  RunStepBlock,
  RunStepComment,
  RunStepFullRedline,
  RunTableInputBlock,
  RunFieldInputConditionalBlock,
  RunTextBlock,
  Step,
  StepRevokeSignoffAction,
  TableInputBlock,
  RunHeaderRedline,
  RedlinedHeader,
  RunRedlineComment,
  ReleaseSection,
  RunStepRedline,
  FieldInputTableRecorded,
  Release,
  RunHeader,
  RunVariable,
} from './types/views/procedures';
import { RunState } from './types/couch/procedures';
import lodash from 'lodash';
import { validateCanEditComment, wasEdited } from './comment';
import tableUtil from './tableUtil';
import ProcedureGraph from './ProcedureGraph';
import timingUtil from './timingUtil';
import {
  updateIdsForIncludingSuggestedEdit,
  updateStepWithAction,
} from './runStepUtil';
import {
  getRedlineId,
  isRedlineAddedStep,
  newStepRedline,
  REDLINE_TYPE,
} from './redlineUtil';
import stepConditionals from './stepConditionals';
import { OperationId } from './types/operations';
import { RunTag } from './types/couch/settings';

export const CONTENT_TYPE = {
  PART_KIT: 'part_kit',
  PART_BUILD: 'part_build',
};

export const RUN_STATE = {
  RUNNING: 'running',
  COMPLETED: 'completed',
  PAUSED: 'paused',
} as const;

export const ACTION_TYPE = {
  ...SIGNOFF_ACTION_TYPE,
  FAIL: 'fail',
  SKIP: 'skip',
  PAUSE: 'pause',
  ISSUE_PAUSE: 'issue pause',
  RISK_PAUSE: 'risk pause',
  ALL_ISSUES_RESOLVED: 'all issues resolved',
  ALL_RISKS_ACCEPTED: 'all risks accepted',
  RESUME: 'resume',
  COMPLETE: 'complete',
  ISSUE: 'issue',
  AUTOMATION_START: 'automation_start',
  AUTOMATION_PAUSE: 'automation_pause',
  AUTOMATION_RESUME: 'automation_resume',
  STEP_ADDED: 'step added',
  REOPEN: 'reopen',
  REDLINE_ADDED: 'redline_added',
  BLUELINE_ADDED: 'blueline_added',
  REDLINE_INCLUDED: 'redline_included',
  BLUELINE_INCLUDED: 'blueline_included',
} as const;

type RunTableRecordedValue = {
  row: number;
  column: number;
  value: string;
};

type RecordedBlocks = {
  [index: number]: {
    value: RunFieldInputRecordedValue | RunTableRecordedValue;
  };
};

type RecordedAllSectionSteps = {
  steps: Array<{
    recorded: RecordedBlocks;
  }>;
};

type RepeatSectionOptions = {
  sectionRepeat: RepeatedSection;
  newToOldStepIds: { [id: string]: string };
};

/*
 * Do not change `ACTIVE_RUN_STATES` while the front end still make direct
 * queries to couchdb.
 *
 * This exists for specific couchdb queries that are based on
 * "in ['running', 'paused']".
 * If the values considered running ever changed, an entirely new index will
 * need to be created because changing this value is not backward-compatible.
 *
 * Changing this value could lead to new organizations being created with
 * indexes that are not consistent with the queries being run against them,
 * causing run listing to fail.
 */
export const ACTIVE_RUN_STATES = [RUN_STATE.RUNNING, RUN_STATE.PAUSED] as const;

export const RUN_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  ABORT: 'abort',
} as const;

export const STEP_STATE = {
  COMPLETED: 'completed',
  FAILED: 'failed',
  SKIPPED: 'skipped',
  PAUSED: 'paused',
  INCOMPLETE: '',
} as const;

export type StepState = (typeof STEP_STATE)[keyof typeof STEP_STATE];

export const getStepState = (step: RunStep): StepState | undefined => {
  if (step.completed) {
    return STEP_STATE.COMPLETED;
  }
  if (step.skipped) {
    return STEP_STATE.SKIPPED;
  }
  return step.state;
};

export const isStepEnded = (step: RunStep): boolean => {
  const stepState = getStepState(step);
  return (
    [
      STEP_STATE.COMPLETED,
      STEP_STATE.SKIPPED,
      STEP_STATE.FAILED,
    ] as Array<StepState>
  ).includes(stepState as StepState);
};

export const isRunStateActive = (runState: RunState | undefined) => {
  return ACTIVE_RUN_STATES.includes(
    runState as (typeof ACTIVE_RUN_STATES)[number]
  );
};

export const isStepStateEnded = (stepState: StepState | undefined): boolean => {
  if (stepState === 'paused') {
    return false;
  }
  return Boolean(stepState);
};

export const stepEndedOrSignedOff = (step: RunStep) => {
  return isStepEnded(step) || signoffUtil.anySignoffsComplete(step);
};

export const canIncludeRedlines = (step: RunStep) =>
  !stepEndedOrSignedOff(step);

// Only first operator can record data when signoffs are required.
export const cannotUpdateStep = (step: RunStep) => {
  return (
    signoffUtil.isSignoffRequired(step.signoffs) && stepEndedOrSignedOff(step)
  );
};

const _isStepEnded = (run: Run, sectionIndex: number, stepIndex: number) => {
  const step = run.sections[sectionIndex].steps[stepIndex];
  return isStepEnded(step);
};

/**
 * Creates an initial version of a run doc for a given procedure.
 *
 * Returns a newly created run doc that is not yet saved to the database.
 * Callers are responsible for saving the new run doc after making any
 * additional changes.
 */
export const newRunDoc = ({
  procedure,
  userId,
  method = 'web',
  userParticipantType,
}: {
  procedure: Release;
  userId?: string;
  method?: 'web' | 'api';
  userParticipantType?: 'participant' | 'viewer';
}) => {
  if (!isReleased(procedure as Procedure)) {
    throw new Error('Cannot run unreleased procedure');
  }
  return runFromProcedure({ procedure, userId, method, userParticipantType });
};

export const newPreviewRunDoc = ({
  procedure,
  userId,
  method = 'web',
  userParticipantType,
}: {
  procedure: Release;
  userId: string;
  method?: 'web' | 'api';
  userParticipantType?: 'participant' | 'viewer';
}) => {
  const reviewComments = (procedure.comments ?? []).filter(
    (comment) => comment.type === 'review_comment'
  );
  const previewRun = runFromProcedure({
    procedure,
    userId,
    method,
    userParticipantType,
  });
  previewRun.comments = reviewComments;
  return previewRun;
};

const runFromProcedure = ({
  procedure,
  userId,
  method = 'web',
  userParticipantType,
}: {
  procedure: Release;
  userId?: string;
  method?: 'web' | 'api';
  userParticipantType?: 'participant' | 'viewer';
}) => {
  const run = cloneDeep(procedure);
  run._id = generateRunId();
  run.procedure = procedure._id;
  run.procedureRev = procedure._rev;
  run.state = RUN_STATE.RUNNING;
  run.starttime = new Date().toISOString();
  run.is_ai_generated = procedure.is_ai_generated || false;
  run.started_by = {
    method,
    user_id: userId ?? null,
  };
  if (userId && userParticipantType) {
    run.participants = [
      {
        user_id: userId,
        created_at: run.starttime,
        type: userParticipantType,
      },
    ];
  }
  run.sections.forEach((section) => {
    section.steps.forEach((step) => {
      step.content.forEach((block) => {
        block.original_id = block.id;
      });
    });
  });
  delete run._rev;
  delete run.comments;
  delete run.actions;

  /**
   * Delete pending block redlines.
   *
   * When editing a procedure, redlines that are pending (not ignored or
   * accepted), are stored as block level redlines and persisted to the
   * procedure when saved. This allows redlines to follow the blocks when
   * dragging and dropping, and also ensures that the pending redlines
   * are not lost when further run changes are made. This also means we
   * need to strip them out when creating a run doc from this procedure.
   */
  run.sections.forEach((section) => {
    section.steps.forEach((step) => {
      step.content.forEach((content) => {
        // @ts-ignore: OK to call even if `redlines` isn't defined
        delete content.redlines;
      });
    });
  });
  return run as Run;
};

const _updateDocWithRunComment = (runDoc, userId, text, createdAt) => {
  // Don't save empty comments
  if (!text) {
    return;
  }
  if (!runDoc.comments) {
    runDoc.comments = [];
  }
  runDoc.comments.push({
    text,
    user_id: userId,
    created_at: createdAt,
  });
};

export const getSectionIndex = (run: Run, sectionId: string): number => {
  const sectionIndex = run.sections.findIndex(
    (section) => section.id === sectionId
  );
  if (sectionIndex === -1) {
    throw new Error(`Invalid section index for section ID ${sectionId}.`);
  }
  return sectionIndex;
};

export const getStepIndex = (
  run: Run,
  stepId: string,
  sectionIndex: number
): number => {
  if (sectionIndex < 0 || sectionIndex >= run.sections.length) {
    throw new Error(`Invalid section index when getting step ID ${stepId}.`);
  }
  const stepIndex = run.sections[sectionIndex].steps.findIndex(
    (step) => step.id === stepId
  );
  if (stepIndex === -1) {
    throw new Error(`Invalid step index for step ID ${stepId}.`);
  }
  return stepIndex;
};

/**
 * Searches a run document for the given section and steps by id.
 * @throws Error getting the section index or step index, if either the section or step ID cannot be found.
 */
export const getSectionAndStepIndices = (
  run: Run,
  sectionId: string,
  stepId: string
): { sectionIndex: number; stepIndex: number } => {
  const sectionIndex = getSectionIndex(run, sectionId);
  const stepIndex = getStepIndex(run, stepId, sectionIndex);
  return { sectionIndex, stepIndex };
};

export const getSectionId = ({
  run,
  stepId,
}: {
  run: Run;
  stepId: string;
}): string | undefined => {
  return run.sections.find((section) =>
    section.steps.some((step) => step.id === stepId)
  )?.id;
};

const _getCommentIdForNewComment = ({
  commentList,
  comment,
}: {
  commentList: Array<RunStepComment>;
  comment: RunStepComment;
}): string => {
  // Generate a commentId if it is not provided
  if (!comment.id) {
    return generateCommentId();
  }

  // Check if duplicate ids exist.
  const existingComment = commentList.find(
    (_comment) => _comment.id === comment.id
  );
  if (existingComment) {
    if (existingComment.text === comment.text) {
      throw new Error('Duplicate id and text');
    }
    // If text is not the same then assign a new id to the new text.
    return generateCommentId();
  }

  return comment.id;
};

const _validateEditComment = ({
  userId,
  comment,
}: {
  userId: string;
  comment: RunStepComment;
}) => {
  if (!comment.id) {
    throw new Error('Edited comment must have id.');
  }

  if (comment.user !== userId) {
    throw new Error('Only the author of a comment can edit the comment.');
  }
};

const _editStepComment = ({
  userId,
  step,
  comment,
}: {
  userId: string;
  step: RunStep;
  comment: RunStepComment;
}) => {
  _validateEditComment({ userId, comment });

  if (!step.comments || step.comments.length === 0) {
    throw new Error('There are no comments available to edit.');
  }

  const commentIndex = step.comments.findIndex(
    (existingComment) => existingComment.id === comment.id
  );

  if (commentIndex === -1) {
    throw new Error('Comment not found.');
  }

  validateCanEditComment(comment.timestamp, comment.updated_at as string);

  step.comments[commentIndex] = comment;
  return true;
};

const _addStepComment = ({
  userId,
  step,
  comment,
}: {
  userId: string;
  step: RunStep;
  comment: RunStepComment;
  timestamp?: string;
}) => {
  if (!step.comments) {
    step.comments = [];
  }
  const commentId = _getCommentIdForNewComment({
    commentList: step.comments,
    comment,
  });

  step.comments.push({
    ...comment,
    id: commentId,
    user: userId,
  });

  return true;
};

const _updateDocWithStepComment = ({
  userId,
  step,
  comment,
}: {
  userId: string;
  step: RunStep;
  comment: RunStepComment;
}) => {
  if (wasEdited(comment.timestamp, comment.updated_at)) {
    return _editStepComment({ userId, step, comment });
  }

  return _addStepComment({ userId, step, comment });
};

const _editTableComment = ({
  userId,
  block,
  rowIndex,
  columnIndex,
  comment,
}: {
  userId: string;
  block: RunTableInputBlock;
  rowIndex: number;
  columnIndex: number;
  comment: RunStepComment;
}) => {
  if (lodash.isNil(rowIndex) || lodash.isNil(columnIndex)) {
    throw new Error('Invalid indices');
  }

  if (block.columns[columnIndex].column_type !== 'comment') {
    throw new Error('Invalid column type');
  }

  if (!block.cells) {
    throw new Error('Missing cells');
  }

  _validateEditComment({ userId, comment });

  const commentList = block.cells[rowIndex][
    columnIndex
  ] as Array<RunStepComment>;

  if (!commentList || commentList.length === 0) {
    throw new Error('There are no comments available to edit.');
  }

  const commentIndex = commentList.findIndex(
    (_comment) => _comment.id === comment.id
  );
  if (commentIndex === -1) {
    throw new Error('Comment not found.');
  }

  validateCanEditComment(comment.timestamp, comment.updated_at as string);

  commentList[commentIndex] = comment;

  return true;
};

export const updateRunWithRedlineStepComment = ({
  run,
  userId,
  stepId,
  commentText,
  commentId,
  updatedAt,
}: {
  run: Run;
  userId: string;
  stepId: string;
  commentText: string;
  commentId: string;
  updatedAt?: string;
}): boolean => {
  if (!run) {
    return false;
  }

  // If run is already completed, drop this request.
  if (run.state === RUN_STATE.COMPLETED) {
    return false;
  }

  const sectionId = getSectionId({ run, stepId });
  if (!sectionId) {
    return false;
  }
  const step = getStepById(run, sectionId, stepId) as RunStep;
  if (!step) {
    return false;
  }

  if (!isSuggestEditsStepSettingEnabled({ step, procedure: run })) {
    return false;
  }

  // Can only edit redline comments in the latest step.
  if (!isLatestStep({ run, sectionId, stepId })) {
    return false;
  }

  if (!step.redline_comments) {
    step.redline_comments = [];
  }

  const existingCommentIndex = step.redline_comments.findIndex(
    (comment) => comment.id === commentId
  );
  if (existingCommentIndex === -1) {
    // Comment id not found: Adding a comment
    const comment = {
      id: commentId,
      redline_id: generateRedlineDocId(),
      text: commentText,
      user_id: userId,
      created_at: new Date().toISOString(),
    };
    step.redline_comments.push(comment);
  } else {
    // Comment id found: Editing a comment
    const existingComment = step.redline_comments[existingCommentIndex];
    if (
      isBeforeReopen({
        actions: run.actions,
        timestamp: existingComment.created_at,
      })
    ) {
      return false;
    }

    const updatedAtOrNow = updatedAt ?? new Date().toISOString();

    try {
      validateCanEditComment(existingComment.created_at, updatedAtOrNow);
    } catch {
      return false;
    }

    existingComment.text = commentText;
    existingComment.updated_at = updatedAtOrNow;
  }

  // Run doc was modified
  return true;
};

export const updateRunWithVariable = (
  run: Run,
  name: string,
  variable: RunVariable
) => {
  if (run.state !== RUN_STATE.RUNNING || !run.variables) {
    return false;
  }

  const index = run.variables.findIndex(
    (variable) => variable.name.toLowerCase() === name.toLowerCase()
  );
  if (index !== -1) {
    run.variables[index] = variable;
    return true;
  }
  return false;
};

const _addTableComment = ({
  userId,
  block,
  rowIndex,
  columnIndex,
  comment,
}) => {
  if (lodash.isNil(rowIndex) || lodash.isNil(columnIndex)) {
    return false;
  }

  const commentList = block.cells[rowIndex][columnIndex];

  const commentId = _getCommentIdForNewComment({
    commentList,
    comment,
  });

  commentList.push({
    ...comment,
    id: commentId,
    user: userId,
  });

  return true;
};

const _updateDocWithTableComment = ({
  userId,
  block,
  rowIndex,
  columnIndex,
  comment,
}: {
  userId: string;
  block: TableInputBlock;
  rowIndex: number;
  columnIndex: number;
  comment: RunStepComment;
}): boolean => {
  if (
    !block.columns[columnIndex] ||
    block.columns[columnIndex].column_type !== 'comment'
  ) {
    return false;
  }
  if (wasEdited(comment.timestamp, comment.updated_at)) {
    return _editTableComment({ userId, block, rowIndex, columnIndex, comment });
  }

  return _addTableComment({ userId, block, rowIndex, columnIndex, comment });
};

export const updateDocWithComment = ({
  userId,
  doc,
  sectionId,
  stepId,
  contentId,
  rowIndex,
  columnIndex,
  comment,
}: {
  userId: string;
  doc: Run;
  sectionId: string;
  stepId: string;
  contentId?: string;
  rowIndex?: number;
  columnIndex?: number;
  comment: RunStepComment;
}): boolean => {
  let sectionIndex: number;
  let stepIndex: number;
  try {
    ({ sectionIndex, stepIndex } = getSectionAndStepIndices(
      doc,
      sectionId,
      stepId
    ));
  } catch (e) {
    return false;
  }

  // Set parent id if it does not exist
  if (!comment.parent_id) {
    comment.parent_id = '';
  }

  if (contentId) {
    const block = doc.sections[sectionIndex].steps[stepIndex].content.find(
      (block) => block.id === contentId
    );
    if (!block) {
      return false;
    }

    if (
      block.type === 'table_input' &&
      !lodash.isNil(rowIndex) &&
      !lodash.isNil(columnIndex)
    ) {
      try {
        return _updateDocWithTableComment({
          userId,
          block,
          rowIndex,
          columnIndex,
          comment,
        });
      } catch (e) {
        return false;
      }
    }

    return false;
  }

  const step = doc.sections[sectionIndex].steps[stepIndex];
  try {
    return _updateDocWithStepComment({
      userId,
      step,
      comment,
    });
  } catch {
    return false;
  }
};

export const updateDocWithRecorded = ({
  run,
  sectionIndex,
  stepIndex,
  recorded,
}: {
  run: Run;
  sectionIndex: number;
  stepIndex: number;
  recorded: Recorded;
}) => {
  const step = run.sections[sectionIndex].steps[stepIndex];

  if (cannotUpdateStep(step)) {
    return;
  }

  if (recorded) {
    Object.keys(recorded).forEach((contentIndex) => {
      if (
        run.sections[sectionIndex].steps[stepIndex].content[contentIndex] &&
        recorded[contentIndex]
      ) {
        const content =
          run.sections[sectionIndex].steps[stepIndex].content[contentIndex];

        if (content.type === 'field_input_table') {
          content.fields.forEach((field, fieldIndex) => {
            field.recorded = recorded[contentIndex][fieldIndex];
          });
        } else {
          content.recorded = recorded[contentIndex];
        }
      }
    });
  }
};

const _updateDocWithUnfinishedStepsSkipped = (
  runDoc,
  userId,
  recordedAllSteps,
  skippedAt
) => {
  runDoc.sections.forEach((section, sectionIndex) => {
    const recordedAllSectionSteps = recordedAllSteps?.sections[sectionIndex];
    updateDocWithSectionSkipped({
      run: runDoc,
      userId,
      sectionId: section.id,
      skippedAt,
      recordedAllSectionSteps,
    });
  });
};

const _updateDocWithRunStatus = (runDoc, status) => {
  // Only allow saving defined run statuses
  if (Object.values(RUN_STATUS).includes(status)) {
    runDoc.status = status;
  }
  if (runDoc.automation_status) {
    delete runDoc.automation_status;
  }
};

const _updateDocWithRunCompletion = (
  runDoc,
  userId,
  recordedAllSteps,
  endedAt
) => {
  _updateDocWithUnfinishedStepsSkipped(
    runDoc,
    userId,
    recordedAllSteps,
    endedAt
  );

  runDoc.state = RUN_STATE.COMPLETED;
  runDoc.completedAt = endedAt;
  runDoc.completedUserId = userId;
};

export const updateDocWithEndRun = (
  runDoc,
  userId,
  comment,
  status,
  recordedAllSteps,
  endedAt
) => {
  // If run is already completed, drop this request.
  if (runDoc.state === RUN_STATE.COMPLETED) {
    return false;
  }

  timingUtil.updateRunWithDurations(
    runDoc as Run,
    undefined,
    undefined,
    endedAt as string
  );
  _updateDocWithRunComment(runDoc, userId, comment, endedAt);
  _updateDocWithRunStatus(runDoc, status);
  _updateDocWithRunCompletion(runDoc, userId, recordedAllSteps, endedAt);

  // Doc was modified
  return true;
};

export const updateDocWithAction = ({
  runDoc,
  type,
  userId,
  timestamp,
  comment,
  context,
}: {
  runDoc: Run;
  type: string;
  userId: string;
  timestamp?: string;
  comment?: string;
  context?: object;
}) => {
  if (!runDoc.actions) {
    runDoc.actions = [];
  }
  runDoc.actions.push({
    type,
    user_id: userId,
    timestamp: timestamp ?? new Date().toISOString(),
    ...(comment && { comment }),
    ...(context && { context }),
  } as RunAction);
};

export const updateDocWithRunReopened = ({
  runDoc,
  userId,
  comment,
  timestamp,
}: {
  runDoc: Run;
  userId: string;
  comment: string;
  timestamp: string;
}) => {
  if (runDoc.state !== RUN_STATE.COMPLETED) {
    return;
  }
  _updateDocWithRunComment(runDoc, userId, comment, timestamp);
  updateDocWithAction({
    runDoc,
    type: ACTION_TYPE.REOPEN,
    userId,
    comment,
    timestamp,
  });
  runDoc.state = RUN_STATE.RUNNING;
  delete runDoc.status;
  delete runDoc.completedAt;
  delete runDoc.completedUserId;
};

const _removeActiveContentFromBlock = (block: RunStepBlock) => {
  if (block.type === 'procedure_link') {
    delete block.run; // delete linked runs
  }
  if (block.type === 'table_input') {
    block.cells = tableUtil.getInitialCells(block);
  }
  if (block.type === 'field_input_table') {
    block.fields.forEach((field) => {
      delete field.recorded;
    });
  }
  delete block['recorded']; // delete recorded data
};

const copyBlockWithoutActiveContent = (block: RunStepBlock) => {
  const updatedBlock = lodash.cloneDeep(block);
  _removeActiveContentFromBlock(updatedBlock);
  return updatedBlock;
};

// Returns copy of step without recorded, signoff, state, or completion data
export const copyStepWithoutActiveContent = <
  T extends Step | RepeatedStep | RedlinedStep
>(
  step: T
): T => {
  const updated = lodash.cloneDeep(step);
  if (updated.content) {
    for (let i = 0; i < updated.content.length; i++) {
      _removeActiveContentFromBlock(updated.content[i]);
    }
  }

  // Clear duration.
  if (updated.duration && typeof updated.duration === 'object') {
    updated.duration = {
      started_at: '',
      duration: '',
    };
  }

  // Clear timer
  if (updated.timer && typeof updated.timer === 'object') {
    const { time_left } = updated.timer;
    updated.timer = {
      started_at: '',
      completed: false,
      time_remaining: '',
      time_left,
    };
  }

  const toDelete = [
    'actions',
    'completed',
    'completedUserId',
    'completedAt',
    'skipped',
    'skippedUserId',
    'skippedAt',
    'repeated_user_id',
    'repeated_at',
    'repeat_of',
    'comments',
    'state',
  ];
  toDelete.forEach((e) => delete updated[e]);
  return updated;
};

/**
 * Create a new step in a run. (Dynamically added steps.)
 *
 * @param {Object} step - The new step object.
 * @param {String} createdAt - Timestamp when the step was created.
 * @param {String} createdBy - Id of user that created the step.
 * @param {Boolean} runOnly - whether the redline will display in the draft
 * @returns {Object} step - New updated step object for insertion into the run doc.
 */
const _createRunStep = (step, createdAt, createdBy, runOnly): RunStep => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return {
    ...step,
    // Do not change the redline id if it already exists.
    redline_id: step.redline_id ?? generateRedlineDocId(),
    created_at: createdAt,
    created_by: createdBy,
    created_during_run: true,
    ...(runOnly && { run_only: runOnly }),
    actions: [
      {
        type: ACTION_TYPE.STEP_ADDED,
        user_id: createdBy,
        timestamp: createdAt,
      },
    ],
  };
};

/**
 * @returns whether the document was updated.
 */
const _insertStep = (
  doc: Run,
  stepToAdd: RunStep,
  sectionId: string,
  precedingStepId: string | null
): boolean => {
  // Don't allow adding steps if the run has ended
  if (doc.state === RUN_STATE.COMPLETED) {
    return false;
  }

  // Don't allow adding a step that already exists
  if (hasStep(doc, stepToAdd.id)) {
    return false;
  }

  const sectionIndex = doc.sections.findIndex(
    (section) => section.id === sectionId
  );
  const steps = doc.sections[sectionIndex].steps;

  // If preceding step id is null, index will be -1 and the step is inserted at the start of the section
  const precedingStepIndex = steps.findIndex(
    (step) => step.id === precedingStepId
  );

  // Lifted in scope to be used outside of for loop
  let trailingStepIndex = precedingStepIndex + 1;

  // Advance trailing to the last repeat of the specified step.
  for (; trailingStepIndex < steps.length; trailingStepIndex += 1) {
    const step = steps[trailingStepIndex];
    if (!(step as RepeatedStep).repeat_of) {
      break;
    }
  }

  // Insert step after the preceding step and its additions or repeats
  doc.sections[sectionIndex].steps.splice(trailingStepIndex, 0, stepToAdd);
  return true;
};

export const updateRunWithStepFailure = ({
  run,
  userId,
  sectionId,
  stepId,
  failedAt,
  recorded,
}: {
  run: Run;
  userId: string;
  sectionId: string;
  stepId: string;
  failedAt: string;
  recorded: RecordedBlocks;
}) => {
  if (run.state !== RUN_STATE.RUNNING) {
    return;
  }

  const { sectionIndex, stepIndex } = getSectionAndStepIndices(
    run,
    sectionId,
    stepId
  );

  if (_isStepEnded(run, sectionIndex, stepIndex)) {
    return;
  }

  timingUtil.updateRunWithDurations(run, sectionId, stepId, failedAt);

  updateDocWithRecorded({ run, sectionIndex, stepIndex, recorded });
  const step = run.sections[sectionIndex].steps[stepIndex];
  step.state = STEP_STATE.FAILED;

  if (!step.actions) {
    step.actions = [];
  }

  step.actions.push({
    type: 'fail',
    timestamp: failedAt,
    user_id: userId,
  });
};

export const updateDocWithAddedStep = ({
  runDoc,
  sectionId,
  precedingStepId,
  step,
  createdAt,
  userId,
  runOnly,
}: {
  runDoc: Run;
  sectionId: string;
  precedingStepId: string | null;
  step: RunStep;
  createdAt: string;
  userId: string;
  runOnly: boolean;
}) => {
  const stepToAdd = _createRunStep(step, createdAt, userId, runOnly);
  return _insertStep(runDoc, stepToAdd, sectionId, precedingStepId);
};

const _repeatsUpToIndex = (repeatables, index) => {
  return repeatables.filter((elem, i) => i <= index && elem.repeat_of).length;
};

// Returns the number of step or section additions up to the provided index
const _runAdditionsUpToIndex = (
  stepOrSectionsList: Array<
    RunSection | RepeatedSection | RunStep | RunAddedStep | RepeatedStep
  >,
  index: number
): number => {
  return stepOrSectionsList.filter(
    (elem, i) => i <= index && (elem as RunAddedStep).created_during_run
  ).length;
};

/**
 * Returns the index of the parent step or section (the index of the step or section
 * in the original procedure) given the index of a step or section in the run.
 *
 * The index of the step or section in the run may be different than the index of
 * the step or section in the procedure because of repeats and additions.
 *
 * stepsOrSectionsList: array of steps or sections which may contain repeats and additions.
 *              Repeats have a repeat_of field and additions have created_during_run: true.
 * index: index of the step or section of interest.
 *
 * returns: the index of the parent step or section. Returns -1 if the step has no
 *          parent, which occurs if the step was added during the run at the
 *          beginning of a section.
 *
 * TODO (jon): refactor this to use ids only and not indexes.
 */
const _getOriginalProcedureIndex = (
  stepsOrSectionsList: Array<
    RunSection | RepeatedSection | RunStep | RunAddedStep | RepeatedStep
  >,
  index: number
) => {
  /**
   * The index of a step or section in the procedure is the index of that step or
   * section in the run if the run were to have:
   *
   * - no repeats
   * - no steps or sections added during the run
   */
  const numRepeatsUpToIndex = _repeatsUpToIndex(stepsOrSectionsList, index);
  const numAdditionsUpToIndex = _runAdditionsUpToIndex(
    stepsOrSectionsList,
    index
  );
  return index - numRepeatsUpToIndex - numAdditionsUpToIndex;
};

/**
 * Returns the id of the section in the original procedure corresponding to a
 * section in a run.
 */
const _getOriginalProcedureSectionId = (
  procedureSections: Array<ReleaseSection>,
  runSections: Array<RunSection | RepeatedSection>,
  runSectionIndex: number
) => {
  const procedureSectionIndex = _getOriginalProcedureIndex(
    runSections,
    runSectionIndex
  );
  return procedureSections[procedureSectionIndex].id;
};

/**
 * Returns the id of the step in the original procedure corresponding to the step
 * specified by the provided indexes in the run.
 *
 * Returns null for steps added at the beginning of a section during a run. Those
 * steps have no corresponding step in the original procedure.
 */
const _getOriginalProcedureStepId = (
  procedureSections: Array<ReleaseSection>,
  runSections: Array<RunSection | RepeatedSection>,
  runSectionIndex: number,
  runStepIndex: number
) => {
  const procedureSectionIndex = _getOriginalProcedureIndex(
    runSections,
    runSectionIndex
  );
  const runSteps = runSections[runSectionIndex].steps;
  const procedureStepIndex = _getOriginalProcedureIndex(runSteps, runStepIndex);
  // Step added at the beginning of a section during a run
  if (procedureStepIndex === -1) {
    return null;
  }
  return procedureSections[procedureSectionIndex].steps[procedureStepIndex].id;
};
/**
 * Get the section id and step id of the original step in the original section. Also return the original step.
 *
 * @param runSections an array of all sections in the run
 * @param runSectionId the section (could be repeated) id of the section in which the step repeat resides
 * @param runStepId the step (could be an original step in a repeated section) id of the step repeat
 * @returns the section and step ids corresponding to the original step in the original section, and the original step
 */
export const getOriginalSectionStepIds = (
  runSections: Array<RunSection | RepeatedSection>,
  runSectionId: string,
  runStepId: string
): {
  originalSectionId: string;
  originalStepId: string | null;
  originalStep: RunStep;
} => {
  const sourceSection = runSections.find(
    (section) => section.id === runSectionId
  );
  const sourceStep = sourceSection?.steps.find((step) => step.id === runStepId);
  if (!sourceSection && !sourceStep) {
    throw new Error('invalid section and step ids');
  }
  if (sourceStep && isRedlineAddedStep(sourceStep)) {
    return {
      originalSectionId: runSectionId,
      originalStepId: runStepId,
      originalStep: sourceStep,
    };
  }

  const procedureSections = cloneDeep(runSections).filter(
    (section): section is RunSection => !isRepeat(section as RepeatedSection)
  );
  procedureSections.forEach((section) => {
    section.steps = section.steps
      .filter((step): step is RunStep => !isRepeat(step as RepeatedStep))
      .filter((step) => !step.created_during_run);
  });
  const runSectionIndex = runSections.findIndex(
    (section) => section.id === runSectionId
  );
  const originalSectionId = _getOriginalProcedureSectionId(
    procedureSections as Array<ReleaseSection>,
    runSections,
    runSectionIndex
  );

  const runStepIndex = runSections[runSectionIndex].steps.findIndex(
    (step) => step.id === runStepId
  );
  const originalStepId = _getOriginalProcedureStepId(
    procedureSections as Array<ReleaseSection>,
    runSections,
    runSectionIndex,
    runStepIndex
  );

  const originalSection = procedureSections.find(
    (section) => section.id === originalSectionId
  );
  const originalStep = originalSection.steps.find(
    (step) => step.id === originalStepId
  );
  return {
    originalSectionId,
    originalStepId,
    originalStep,
  };
};

export const updateStepWithOriginalIdLinks = ({
  run,
  sectionId,
  stepId,
  step,
}: {
  run: Run;
  sectionId: string;
  stepId: string;
  step: RunStep | RedlinedStep;
}) => {
  if (step.content.some((block) => !block.original_id)) {
    const { originalStep } = getOriginalSectionStepIds(
      run.sections,
      sectionId,
      stepId
    );
    if (!originalStep) {
      return;
    }
    const originalStepContent = originalStep.content.filter(
      (block) => !block.added
    );
    step.content
      .filter((block) => !block.added)
      .forEach((block, blockIndex) => {
        block.original_id = originalStepContent[blockIndex].id;
      });
  }
};

export const updateDocWithFullStepRedline = ({
  runDoc,
  sectionId,
  stepId,
  redline,
  userId,
  includeInRun,
}: {
  runDoc: Run;
  sectionId: string;
  stepId: string;
  redline: RunStepFullRedline;
  userId: string;
  includeInRun: boolean;
}) => {
  if (!runDoc) {
    throw new Error('Missing run document');
  }
  // If run is already completed, drop this request.
  if (runDoc.state === RUN_STATE.COMPLETED) {
    return false;
  }

  const existingStep = getStepById(runDoc, sectionId, stepId) as RunStep;
  updateStepWithOriginalIdLinks({
    run: runDoc,
    sectionId,
    stepId,
    step: existingStep,
  });
  existingStep.redlines?.forEach((redline) => {
    updateStepWithOriginalIdLinks({
      run: runDoc,
      sectionId,
      stepId,
      step: redline.step,
    });
  });

  if (
    !isSuggestEditsStepSettingEnabled({
      step: existingStep,
      procedure: runDoc,
    })
  ) {
    return false;
  }
  // The redline needs to be copied so that the front-end updates via redux do not get passed directly to the backend.
  const redlineCopy = lodash.cloneDeep(redline);

  if (
    !existingStep.redlines ||
    existingStep.redlines.length === 0 ||
    !(existingStep.redlines[0] as RunStepFullRedline).is_original
  ) {
    const originalPseudoBlueline = newStepRedline({
      step: existingStep,
      userId,
      pending: false,
      fieldOrBlockMetadata: {},
      isRedline: false,
      createdAt: redlineCopy.created_at ?? redlineCopy.createdAt,
      type: REDLINE_TYPE.FULL_STEP_REDLINE,
    }) as RunStepFullRedline;

    originalPseudoBlueline.is_original = true;
    // For purposes of record-keeping and diffing, store the original step as a pseudo blueline at the beginning of the redlines array.
    if (!existingStep.redlines) {
      existingStep.redlines = [];
    }
    existingStep.redlines.unshift(originalPseudoBlueline);
  }

  existingStep.redlines.push(redlineCopy);

  const isRedline = !redlineCopy.run_only;
  updateStepWithAction({
    step: existingStep,
    type: isRedline ? ACTION_TYPE.REDLINE_ADDED : ACTION_TYPE.BLUELINE_ADDED,
    userId,
    timestamp:
      redlineCopy.created_at ??
      redlineCopy.createdAt ??
      new Date().toISOString(),
    context: {
      redline_id: getRedlineId(redline),
    },
  });

  if (includeInRun) {
    includeFullStepRedline({
      runDoc,
      userId,
      sectionId,
      stepId,
      redlineId: getRedlineId(redlineCopy) ?? '',
      includedAt: redlineCopy.created_at ?? new Date().toISOString(),
      addAction: false,
    });
  }

  return true;
};

export const includeFullStepRedline = ({
  runDoc,
  userId,
  sectionId,
  stepId,
  redlineId,
  includedAt,
  addAction = true,
}: {
  runDoc: Run;
  userId: string;
  sectionId: string;
  stepId: string;
  redlineId: string;
  includedAt: string;
  addAction?: boolean;
}) => {
  const { sectionIndex, stepIndex } = getSectionAndStepIndices(
    runDoc,
    sectionId,
    stepId
  );

  const existingStep = runDoc.sections[sectionIndex].steps[stepIndex];
  if (!redlineId || !canIncludeRedlines(existingStep)) {
    return false;
  }
  const redline = existingStep.redlines?.find(
    (redline) => redline.redline_id === redlineId
  );
  if (!redline || !redline.pending) {
    return false;
  }
  // Replace the step values with values from the redline.
  const redlineStepWithExistingIds = updateIdsForIncludingSuggestedEdit(
    existingStep,
    redline.step
  );
  const updatedStep: RunStep = {
    ...existingStep,
    name: redlineStepWithExistingIds.name,
    content: redlineStepWithExistingIds.content.map((block) =>
      _mergeTableComments({ block, step: existingStep })
    ),
    redline_id: redline.redline_id,
  };

  // Mark the redline as included.
  redline.pending = false;

  runDoc.sections[sectionIndex].steps[stepIndex] =
    updateIdsForIncludingSuggestedEdit(existingStep, updatedStep);

  if (addAction) {
    const isRedline = !redline.run_only;
    updateStepWithAction({
      step: runDoc.sections[sectionIndex].steps[stepIndex],
      type: isRedline
        ? ACTION_TYPE.REDLINE_INCLUDED
        : ACTION_TYPE.BLUELINE_INCLUDED,
      userId,
      timestamp: includedAt,
      context: {
        redline_id: redlineId,
      },
    });
  }

  return true;
};

const _mergeTableComments = ({
  block,
  step,
}: {
  block: RunStepBlock;
  step: RunStep;
}): RunStepBlock => {
  if (block.type !== 'table_input') {
    return block;
  }

  const existingBlockWithActiveContent: RunTableInputBlock | undefined =
    step.content.find(
      (current): current is RunTableInputBlock => current.id === block.id
    );
  if (!existingBlockWithActiveContent) {
    return block;
  }

  const existingBlockWithoutActiveContent = copyBlockWithoutActiveContent(
    existingBlockWithActiveContent
  );
  const blockCopy = lodash.cloneDeep(block);

  // Always include table comments if the blocks are identical except for active content.
  if (lodash.isEqual(block, existingBlockWithoutActiveContent)) {
    blockCopy.columns?.forEach((column, columnIndex) => {
      if (column.column_type !== 'comment') {
        return;
      }
      blockCopy.cells?.forEach((row, rowIndex) => {
        const existingComments =
          existingBlockWithActiveContent.cells?.[rowIndex][columnIndex];
        if (!existingComments) {
          return;
        }
        row[columnIndex] = existingComments;
      });
    });

    return blockCopy;
  }

  if (existingBlockWithActiveContent.cells) {
    // Only carry over cells that have matching ids
    const previousValueMap = tableUtil.getCoordinateToValueMap({
      block: existingBlockWithActiveContent,
      values: existingBlockWithActiveContent.cells,
    });
    const commentColumnIndices = blockCopy.columns
      .map((col, index) => (col.column_type === 'comment' ? index : null))
      .filter((a): a is number => a !== null);
    if (commentColumnIndices.length > 0) {
      blockCopy.cells?.forEach((row, rowIndex) => {
        const rowId = blockCopy.row_metadata?.[rowIndex].id;
        if (!rowId) {
          return;
        }
        commentColumnIndices.forEach((columnIndex) => {
          const columnId = blockCopy.columns[columnIndex].id;
          if (!columnId) {
            return;
          }
          const key = tableUtil.getCoordinateKey({ rowId, columnId });
          const value = previousValueMap[key];
          if (
            value !== undefined &&
            blockCopy.cells &&
            tableUtil.isValidCellValue({
              block: blockCopy,
              rowId,
              columnId,
              value,
            })
          ) {
            blockCopy.cells[rowIndex][columnIndex] = value;
          }
        });
      });
    }
    return blockCopy;
  }

  return blockCopy;
};

const getLatestAction = ({
  actions,
  type,
}: {
  actions?: Array<RunAction>;
  type: RunAction['type'];
}): RunAction | undefined => {
  if (!actions || actions.length === 0) {
    return;
  }
  return actions
    .filter((action) => action.type === type)
    .reverse()
    .at(0);
};

export const isBeforeReopen = ({
  actions,
  timestamp,
}: {
  actions?: Array<RunAction>;
  timestamp: string;
}) => {
  const latestReopenAction = getLatestAction({
    actions,
    type: 'reopen',
  });

  return latestReopenAction && latestReopenAction.timestamp >= timestamp;
};

export const isLatestStep = ({
  run,
  sectionId,
  stepId,
}: {
  run: Run;
  sectionId: string;
  stepId: string;
}) => {
  const { originalSectionId, originalStepId } = getOriginalSectionStepIds(
    run.sections,
    sectionId,
    stepId
  );

  if (!originalStepId || !originalSectionId) {
    return false;
  }

  const { latestSectionId, latestStep } = getLatestStepContext({
    sourceRun: run,
    originalSectionId,
    originalStepId,
  });

  return sectionId === latestSectionId && stepId === latestStep.id;
};

export const updateDocWithSuggestedEditCommentEdit = ({
  userId,
  runDoc,
  sectionId,
  stepId,
  redlineId,
  commentId,
  updatedText,
  updatedAt,
}: {
  userId: string;
  runDoc: Run;
  sectionId: string;
  stepId: string;
  redlineId: string;
  commentId: string;
  updatedText: string;
  updatedAt: string;
}) => {
  const getComment = ({
    runDoc,
    sectionId,
    stepId,
  }: {
    runDoc: Run;
    sectionId: string;
    stepId: string;
  }) => {
    // Can only edit redline comments in the latest step.
    if (!isLatestStep({ run: runDoc, sectionId, stepId })) {
      return;
    }

    const existingStep = getStepById(runDoc, sectionId, stepId) as RunStep;

    const redline = existingStep.redlines?.find(
      (redline) => getRedlineId(redline) === redlineId
    );
    if (!redline) {
      return;
    }

    return (redline as RunStepFullRedline).comments?.find(
      (comment) => comment.id === commentId
    );
  };

  return updateDocWithCommentEdit({
    userId,
    runDoc,
    sectionId,
    stepId,
    commentId,
    updatedText,
    updatedAt,
    canEditAfterReopen: false,
    getComment,
  });
};

export const updateDocWithCommentEdit = ({
  userId,
  runDoc,
  sectionId,
  stepId,
  updatedText,
  updatedAt,
  canEditAfterReopen,
  getComment,
}: {
  userId: string;
  runDoc: Run;
  sectionId: string;
  stepId: string;
  commentId: string;
  updatedText: string;
  updatedAt: string;
  canEditAfterReopen: boolean;
  getComment: ({
    runDoc,
    sectionId,
    stepId,
  }: {
    runDoc: Run;
    sectionId: string;
    stepId: string;
  }) => RunRedlineComment | RunStepComment | undefined;
}) => {
  if (!runDoc) {
    throw new Error('Missing run document');
  }
  // If run is already completed, drop this request.
  if (runDoc.state !== RUN_STATE.RUNNING) {
    return false;
  }

  const existingComment = getComment({ runDoc, sectionId, stepId });
  if (!existingComment) {
    return false;
  }
  const existingUserId =
    (existingComment as RunRedlineComment).user_id ??
    (existingComment as RunStepComment).user;
  if (
    // Only the original author can edit the comment.
    existingUserId !== userId ||
    // Do not update the comment if the new text is identical to the existing text.
    existingComment.text === updatedText
  ) {
    return false;
  }

  // Comments can only be edited a certain amount of time after they are created.
  const createdAt =
    (existingComment as RunRedlineComment).created_at ??
    (existingComment as RunStepComment).timestamp;
  try {
    validateCanEditComment(createdAt, updatedAt);
  } catch {
    return false;
  }

  if (
    !canEditAfterReopen &&
    isBeforeReopen({ actions: runDoc.actions, timestamp: createdAt })
  ) {
    return false;
  }

  existingComment.text = updatedText;
  existingComment.updated_at = updatedAt;

  return true;
};

export const displaySectionKey = (sections, sectionIndex, style) => {
  const repeatsUpToIndex = _repeatsUpToIndex(sections, sectionIndex);
  return procedureSectionKey(sectionIndex - repeatsUpToIndex, style);
};

export const displaySectionStepKey = (
  sections,
  sectionIndex,
  stepIndex,
  style
) => {
  const repeatsSectionUpToIndex =
    sectionIndex - _repeatsUpToIndex(sections, sectionIndex);
  const repeatsStepUpToIndex =
    stepIndex - _repeatsUpToIndex(sections[sectionIndex].steps, stepIndex);
  return procedureSectionStepKey(
    repeatsSectionUpToIndex,
    repeatsStepUpToIndex,
    style
  );
};

export const IRREVOCABLE_BLOCK_TYPE_SET = new Set([
  'part_kit',
  'part_build',
  'part_usage',
  'tool_check_out',
  'tool_check_in',
  'tool_usage',
]);

const _commonValidateSignoff = ({
  run,
  signoffId,
  step,
  userOperatorRolesSet,
}: {
  run: Run;
  signoffId: string;
  step: RunStep;
  userOperatorRolesSet: Set<string>;
}) => {
  if (run.state !== RUN_STATE.RUNNING) {
    throw new Error('Run must be running.');
  }

  if (!signoffId) {
    throw new Error('Signoff id is required.');
  }

  if (!signoffUtil.isSignoffRequired(step.signoffs)) {
    throw new Error('No signoffs are required.');
  }

  const signoff = step.signoffs.find((signoff) => signoff.id === signoffId);
  if (!signoff) {
    throw new Error('Signoff not found.');
  }

  if (
    !signoffUtil.isGenericSignoffRequired(step.signoffs) &&
    !signoffUtil.requiresAnyRoles(signoff, Array.from(userOperatorRolesSet))
  ) {
    throw new Error('User does not have any operator roles for this signoff.');
  }

  if (!new ProcedureGraph(run).areRequirementsMet(step.id)) {
    throw new Error('Requirements must be fulfilled.');
  }
};

export const checkCanSignOffStep = ({
  run,
  step,
  signoffId,
  operator,
  userOperatorRolesSet,
  timestamp,
  userId,
}: {
  run: Run;
  step: RunStep;
  signoffId: string;
  operator: string;
  userOperatorRolesSet: Set<string>;
  timestamp: string;
  userId: string;
}) => {
  _commonValidateSignoff({
    run,
    signoffId,
    step,
    userOperatorRolesSet,
  });

  canSignOffStepValidation({
    step,
    signoffId,
    operator,
    userOperatorRolesSet,
    timestamp,
    userId,
  });
};

export const canSignOffStepValidation = ({
  step,
  signoffId,
  operator,
  userOperatorRolesSet,
  timestamp,
  userId,
}: {
  step: RunStep;
  signoffId: string;
  operator: string;
  userOperatorRolesSet: Set<string>;
  timestamp: string;
  userId: string;
}) => {
  if (
    !signoffUtil.isGenericSignoffRequired(step.signoffs) &&
    !userOperatorRolesSet.has(operator)
  ) {
    throw new Error('User does not have the required operator role.');
  }

  const stepState = getStepState(step);
  if (
    stepState &&
    !([STEP_STATE.INCOMPLETE, STEP_STATE.PAUSED] as Array<StepState>).includes(
      stepState
    )
  ) {
    throw new Error('Signoff can only be made on in-progress or paused steps.');
  }

  const latestSignoffAction = signoffUtil.getLatestSignoffAction(
    step.actions ?? [],
    signoffId
  );

  if (
    latestSignoffAction &&
    latestSignoffAction.type !== SIGNOFF_ACTION_TYPE.REVOKE_SIGNOFF
  ) {
    throw new Error(
      'Step must be un-signed-off or revoked in order to approve a signoff.'
    );
  }

  if (latestSignoffAction && latestSignoffAction.timestamp >= timestamp) {
    throw new Error('Signoff action must happen after its latest revocation.');
  }

  if (
    signoffUtil.isRoleSignedOffAnywhere({ operator, userId, signoffable: step })
  ) {
    throw new Error(
      `Signoff user ${userId} has already signed off on this role`
    );
  }
};

export const checkCanRevokeStepApproval = ({
  run,
  sectionId,
  stepId,
  signoffId,
  userOperatorRolesSet,
  timestamp,
}: {
  run: Run;
  sectionId: string;
  stepId: string;
  signoffId: string;
  userOperatorRolesSet: Set<string>;
  timestamp: string;
}) => {
  const step: RunStep = getStepById(run, sectionId, stepId);
  _commonValidateSignoff({ run, signoffId, step, userOperatorRolesSet });

  // Do not allow revoking signoff if step is fully signed off and any of the disallowed block types are present.
  if (signoffUtil.allSignoffsComplete(step)) {
    for (const contentBlock of step.content) {
      if (IRREVOCABLE_BLOCK_TYPE_SET.has(contentBlock.type)) {
        throw new Error(
          `Signoff cannot be revoked for step with block type: ${contentBlock.type}`
        );
      }
    }
  }

  const stepState = getStepState(step);
  if (
    stepState &&
    !(
      [
        STEP_STATE.COMPLETED,
        STEP_STATE.INCOMPLETE,
        STEP_STATE.PAUSED,
      ] as Array<StepState>
    ).includes(stepState)
  ) {
    throw new Error(
      'Signoff can only be revoked on completed or in-progress steps.'
    );
  }

  const latestSignoffAction = signoffUtil.getLatestSignoffAction(
    step.actions ?? [],
    signoffId
  );
  if (latestSignoffAction?.type !== SIGNOFF_ACTION_TYPE.SIGNOFF) {
    throw new Error('Signoff cannot be revoked for incomplete signoff.');
  }
  if (latestSignoffAction.timestamp >= timestamp) {
    throw new Error(
      'Revoke action must happen after the signoff it is revoking.'
    );
  }
};

export const updateDocWithStepSignoffRevoked = ({
  run,
  userId,
  sectionId,
  stepId,
  signoffId,
  userOperatorRolesSet,
  timestamp,
}: {
  run: Run;
  userId: string;
  sectionId: string;
  stepId: string;
  signoffId: string;
  userOperatorRolesSet: Set<string>;
  timestamp: string;
}): boolean => {
  try {
    checkCanRevokeStepApproval({
      run,
      sectionId,
      stepId,
      signoffId,
      userOperatorRolesSet,
      timestamp,
    });
  } catch (e) {
    return false;
  }

  const step: RunStep = getStepById(run, sectionId, stepId);

  // Add revoke signoff action.
  if (!step.actions) {
    step.actions = [];
  }
  step.actions.push(
    signoffUtil.getRevokeSignoffAction({
      userId,
      signoffId,
      timestamp,
      actions: step.actions,
    }) as StepRevokeSignoffAction
  );

  const stepState = getStepState(step);

  // Remove completed state if it exists.
  if (stepState === STEP_STATE.COMPLETED) {
    delete step.completed;
    delete step.completedAt;
    delete step.completedUserId;
  }

  // If there are no longer any signoffs, remove recorded values.
  if (!signoffUtil.anySignoffsComplete(step)) {
    // Restart duration timer if it exists.
    if (step.duration && typeof step.duration === 'object') {
      step.duration.duration = '';
    }

    // Remove recorded if it exists.
    for (const contentBlock of step.content) {
      delete contentBlock['recorded'];
    }
  }

  return true;
};

export const updateDocWithLinkedRun = (
  run: Run,
  sectionId: string,
  stepId: string,
  contentId: string,
  linkedRunId: string
): boolean => {
  const { sectionIndex, stepIndex } = getSectionAndStepIndices(
    run,
    sectionId,
    stepId
  );
  const contentIndex = run.sections[sectionIndex].steps[
    stepIndex
  ].content.findIndex((content) => content.id === contentId);
  if (contentIndex === -1) {
    throw new Error('Invalid content index.');
  }

  // If run is already linked, drop this request
  if (
    (
      run.sections[sectionIndex].steps[stepIndex].content[
        contentIndex
      ] as RunProcedureLinkBlock
    ).run === linkedRunId
  ) {
    return false;
  }

  // Link run.
  (
    run.sections[sectionIndex].steps[stepIndex].content[
      contentIndex
    ] as RunProcedureLinkBlock
  ).run = linkedRunId;

  // Run doc was modified
  return true;
};

export const removeLinkedRunFromDoc = (
  run: Run,
  sectionId: string,
  stepId: string,
  contentId: string,
  linkedRunId: string
): void => {
  const { sectionIndex, stepIndex } = getSectionAndStepIndices(
    run,
    sectionId,
    stepId
  );
  const contentIndex = run.sections[sectionIndex].steps[
    stepIndex
  ].content.findIndex((content) => content.id === contentId);
  if (contentIndex === -1) {
    return;
  }

  const block = run.sections[sectionIndex].steps[stepIndex].content[
    contentIndex
  ] as RunProcedureLinkBlock;

  if (block?.run === linkedRunId) {
    delete block.run;
  }
};

export const updateDocWithParticipantType = (
  run: Run,
  userId: string,
  createdAt: string,
  type: 'participant' | 'viewer'
): void => {
  if (!run.participants) {
    run.participants = [];
  }

  const participant = {
    user_id: userId,
    created_at: createdAt,
    type,
  };

  const participantIndex = run.participants.findIndex(
    (p) => p.user_id === userId
  );
  if (participantIndex === -1) {
    run.participants.push(participant);
  } else {
    run.participants[participantIndex] = participant;
  }
};

export const updateDocWithParticipantAdded = (
  run: Run,
  userId: string,
  createdAt: string
): void => {
  updateDocWithParticipantType(run, userId, createdAt, 'participant');
};

export const updateDocWithParticipantRemoved = (
  run: Run,
  userId: string,
  createdAt: string
): void => {
  updateDocWithParticipantType(run, userId, createdAt, 'viewer');
};

const updateRunWithRecorded = (
  run: Run,
  sectionIndex: number,
  stepIndex: number,
  recorded: RecordedValues
): void => {
  const step = run.sections[sectionIndex].steps[stepIndex];
  // Only first operator can record data
  if (cannotUpdateStep(step)) {
    return;
  }

  // Add recorded data
  if (recorded) {
    Object.keys(recorded).forEach((contentIndex) => {
      const index = parseInt(contentIndex, 10);
      const contentItem =
        run.sections[sectionIndex].steps[stepIndex].content[index];
      if (contentItem && recorded[contentIndex]) {
        if (contentItem.type === 'field_input_table' && contentItem.fields) {
          Object.keys(
            recorded[contentIndex] as FieldInputTableRecorded
          ).forEach((fieldIndex) => {
            const fieldIdx = parseInt(fieldIndex, 10);
            if (
              contentItem.fields[fieldIdx] &&
              recorded[contentIndex][fieldIndex]
            ) {
              contentItem.fields[fieldIdx].recorded =
                recorded[contentIndex][fieldIndex];
            }
          });
        } else {
          // For non-FieldInputTable types
          // @ts-ignore recorded doesn't exist on RunAttachmentBlock
          contentItem.recorded = recorded[contentIndex];
        }
      }
    });
  }
};

const updateStepWithSignoffCompletion = ({
  step,
  userId,
  timestamp,
}: {
  step: RunStep;
  userId: string;
  timestamp: string;
}): void => {
  step.completed = true;
  step.completedAt = timestamp;
  step.completedUserId = userId;
};

export const updateRunWithStepSignOff = ({
  run,
  userId,
  sectionId,
  stepId,
  timestamp,
  signoffId,
  operator,
  recorded,
  deviceUserId,
}: {
  run: Run;
  userId: string;
  sectionId: string;
  stepId: string;
  timestamp: string;
  signoffId: string;
  operator: string;
  recorded: RecordedValues;
  deviceUserId?: string;
}): void => {
  const { sectionIndex, stepIndex } = getSectionAndStepIndices(
    run,
    sectionId,
    stepId
  );

  const step = run.sections[sectionIndex].steps[stepIndex];

  if (!step.actions) {
    step.actions = [];
  }

  timingUtil.updateRunWithDurations(
    run,
    run.sections[sectionIndex].id,
    stepId,
    timestamp
  );
  updateRunWithRecorded(run, sectionIndex, stepIndex, recorded);

  const conditionalValue = stepConditionals.getRecordedConditionalValue(
    recorded as RunFieldInputConditionalBlock,
    step
  );

  step.actions.push({
    type: 'signoff',
    signoff_id: signoffId,
    operator,
    timestamp,
    user_id: userId,
    ...(conditionalValue && { conditional_value: conditionalValue }),
    ...(deviceUserId && { device_user_id: deviceUserId }),
  });

  if (signoffUtil.allSignoffsComplete(step)) {
    updateStepWithSignoffCompletion({
      step,
      userId,
      timestamp,
    });
  }
};

export const updateRunWithStepComplete = ({
  run,
  userId,
  sectionId,
  stepId,
  timestamp,
  recorded,
}: {
  run: Run;
  userId: string;
  sectionId: string;
  stepId: string;
  timestamp: string;
  recorded: RecordedValues;
}): void => {
  if (run.state === RUN_STATE.COMPLETED) {
    return;
  }

  const { sectionIndex, stepIndex } = getSectionAndStepIndices(
    run,
    sectionId,
    stepId
  );
  const step = run.sections[sectionIndex].steps[stepIndex];

  if (!step.actions) {
    step.actions = [];
  }

  timingUtil.updateRunWithDurations(run, sectionId, stepId, timestamp);
  updateRunWithRecorded(run, sectionIndex, stepIndex, recorded);

  const conditionalValue = stepConditionals.getRecordedConditionalValue(
    recorded as RunFieldInputConditionalBlock,
    step
  );

  step.actions?.push({
    type: 'complete',
    timestamp,
    user_id: userId,
    ...(conditionalValue && { conditional_value: conditionalValue }),
  });

  updateStepWithSignoffCompletion({
    step,
    userId,
    timestamp,
  });
};

export const shouldRecordExpressionForBlock = (block: RunStepBlock) => {
  return (
    block.type === 'expression' ||
    (isTokenizedTextBlock(block) &&
      (block as RunTextBlock).tokens?.some(
        (token) => token.type === 'reference'
      ))
  );
};

export const updateRunWithStepDetail = ({
  run,
  sectionId,
  stepId,
  field,
  value,
}: {
  run: Run;
  sectionId: string;
  stepId: string;
  field: string;
  value: unknown;
}) => {
  if (run.state !== RUN_STATE.RUNNING) {
    return;
  }

  const allowedFields = ['duration', 'timer'];
  const { sectionIndex, stepIndex } = getSectionAndStepIndices(
    run,
    sectionId,
    stepId
  );
  const step = run.sections[sectionIndex].steps[stepIndex];

  if (
    !step ||
    !allowedFields.includes(field) ||
    isStepEnded(step) ||
    cannotUpdateStep(step)
  ) {
    return;
  }

  step[field] = value;
  return;
};

export const updateRunWithOperation = (run: Run, operation: OperationId) => {
  run.operation = operation;
};

export const updateRunWithOperationCleared = (run: Run) => {
  if (run.state !== 'running') {
    return;
  }
  delete run.operation;
};

export const updateRunWithTags = (run: Run, runTags: Array<RunTag>) => {
  run.run_tags = runTags;
};

export const updateRunWithGlobalTagId = (run: Run, tagId: string) => {
  if (!run.global_run_tag_ids) {
    run.global_run_tag_ids = [];
  }
  run.global_run_tag_ids.push(tagId);
};

export const updateRunRemoveGlobalTagId = (run: Run, tagId: string) => {
  if (!run.global_run_tag_ids) {
    return;
  }
  run.global_run_tag_ids = run.global_run_tag_ids.filter((id) => id !== tagId);
};

export const updateRunWithHeaderRedline = ({
  run,
  userId,
  headerId,
  redlinedHeader,
  isPending,
  contentId,
  field,
  isRedline,
  createdAt,
}: {
  run: Run;
  userId: string;
  headerId: string;
  redlinedHeader: RedlinedHeader;
  isPending?: boolean;
  contentId?: string;
  field?: 'name';
  isRedline: boolean;
  createdAt: string;
}) => {
  if (run.state !== RUN_STATE.RUNNING) {
    return;
  }

  if (!run.headers) {
    throw new Error('Run does not have the headers field');
  }
  const headerIndex = run.headers.findIndex((header) => header.id === headerId);
  if (headerIndex === -1) {
    throw new Error('Invalid header id');
  }
  if (!contentId && !field) {
    throw new Error('Either contentId or field is required');
  }
  const header = run.headers[headerIndex];
  if (!header.redlines) {
    header.redlines = [];
  }

  const runHeaderRedline = {
    redline_id: generateRedlineDocId(),
    created_at: createdAt,
    user_id: userId,
    header: redlinedHeader,
    run_only: !isRedline,
    pending: Boolean(isPending),
  };

  // This object either has "content_id" or "field", but not both
  let updatedRedline: RunHeaderRedline;
  if (contentId) {
    updatedRedline = {
      ...runHeaderRedline,
      content_id: contentId,
    };
  } else {
    updatedRedline = {
      ...runHeaderRedline,
      field: 'name',
    };
  }

  header.redlines.push(updatedRedline);
};

export const updateRunWithAcceptedHeaderRedline = ({
  run,
  userId,
  headerId,
  redlineIndex,
  acceptedAt,
}: {
  run: Run;
  userId: string;
  headerId: string;
  redlineIndex: number;
  acceptedAt: string;
}) => {
  if (!run) {
    throw new Error('Missing run document');
  }
  const header = getHeaderById(run, headerId) as RunHeader;
  if (!header?.redlines?.[redlineIndex]?.pending) {
    throw new Error('Redline is not pending');
  }
  header.redlines[redlineIndex].pending = false;
  header.redlines[redlineIndex].accepted_by = userId;
  header.redlines[redlineIndex].accepted_at = acceptedAt;
};

/**
 * Gets the latest repeat of a source repeatable (a repeatable is a section or a step)
 */
const getLastRepeat = (
  repeatables: Array<RunSection | RunStep | RepeatedSectionOrStep>,
  sourceRepeatableId: string
): RepeatedSectionOrStep | null => {
  let lastRepeat = repeatables.find(
    (repeatable) =>
      (repeatable as RepeatedSectionOrStep).repeat_of === sourceRepeatableId
  );
  if (!lastRepeat) {
    return null;
  }
  const lastRepeatIndex = repeatables.findIndex(
    (repeatable) => repeatable.id === lastRepeat?.id
  );

  const numRepeatables = repeatables.length;
  for (
    let nextRepeatableIndex = lastRepeatIndex + 1;
    nextRepeatableIndex < numRepeatables;
    nextRepeatableIndex++
  ) {
    const nextRepeatable = repeatables[nextRepeatableIndex];
    if ((nextRepeatable as RepeatedSectionOrStep).repeat_of === lastRepeat.id) {
      lastRepeat = nextRepeatable;
    } else {
      break;
    }
  }

  return lastRepeat as RepeatedSectionOrStep;
};

/*
 * When a step or section is repeated, it may be the target of a conditional or the source
 * of a dependency for other steps/sections. This function updates those dependency
 * references from the old ID to the new ID.
 */
export const updateDependencyIds = (
  runGraph: ProcedureGraph,
  oldId: string,
  newId: string
) => {
  const conditonalParents = runGraph.getConditionalParentIds(oldId);
  for (const parentId of conditonalParents) {
    // cast parent as step since sections don't have conditionals - yet
    const parent = runGraph.getVertexBlock(parentId) as Step | undefined;
    parent?.conditionals?.forEach((conditional) => {
      if (conditional.target_id === oldId) {
        conditional.target_id = newId;
      }
    });
  }
  const dependencyChildren = runGraph.getDependencyChildrenIds(oldId);
  for (const childId of dependencyChildren) {
    const child = runGraph.getVertexBlock(childId);
    child?.dependencies?.forEach((dependency) => {
      dependency.dependent_ids.forEach((dependentId, index) => {
        if (dependentId === oldId) {
          dependency.dependent_ids[index] = newId;
        }
      });
    });
  }
};

export const updateDocWithStepSkipped = ({
  run,
  userId,
  sectionId,
  stepId,
  skippedAt,
  recorded,
  isSkippedInRepeat,
}: {
  run: Run;
  userId: string;
  sectionId: string;
  stepId: string;
  skippedAt: string;
  recorded: RecordedBlocks;
  isSkippedInRepeat?: boolean;
}): boolean => {
  // If run is already completed, drop this request.
  if (run.state === RUN_STATE.COMPLETED) {
    return false;
  }
  const { sectionIndex, stepIndex } = getSectionAndStepIndices(
    run,
    sectionId,
    stepId
  );
  // If step is already ended, drop this request
  if (_isStepEnded(run, sectionIndex, stepIndex)) {
    return false;
  }

  const step = run.sections[sectionIndex].steps[stepIndex];
  if (isSkippedInRepeat) {
    if (!isRepeatStepSettingEnabled({ step, procedure: run })) {
      return false;
    }
  } else if (!isSkipStepSettingEnabled({ step, procedure: run })) {
    return false;
  }

  updateDocWithRecorded({ run, sectionIndex, stepIndex, recorded });

  const runSection = run.sections[sectionIndex];
  const runStep = runSection.steps[stepIndex];

  timingUtil.updateRunWithDurations(run, runSection.id, runStep.id, skippedAt);

  if (runStep.state === 'paused') {
    delete runStep.state;
  }

  runStep.skipped = true;
  runStep.skippedAt = skippedAt;
  runStep.skippedUserId = userId;

  // Run doc was modified
  return true;
};

export const updateDocWithSectionSkipped = ({
  run,
  userId,
  sectionId,
  skippedAt,
  recordedAllSectionSteps,
  isRepeat,
}: {
  run: Run;
  userId: string;
  sectionId: string;
  skippedAt: string;
  recordedAllSectionSteps: RecordedAllSectionSteps;
  isRepeat?: boolean;
}): boolean => {
  if (run.state === RUN_STATE.COMPLETED) {
    return false;
  }

  const sectionIndex = getSectionIndex(run, sectionId);

  run.sections[sectionIndex]?.steps.forEach((step, stepIndex) => {
    const recorded = recordedAllSectionSteps?.steps[stepIndex]?.recorded;
    updateDocWithStepSkipped({
      run,
      userId,
      sectionId,
      stepId: step.id,
      skippedAt,
      recorded,
      isSkippedInRepeat: isRepeat,
    });
  });

  // Run doc was modified
  return true;
};

export const updateDocWithStepRepeated = ({
  run,
  userId,
  recorded,
  stepRepeat,
  sectionId,
  sourceStepId,
  includeRedlines,
}: {
  run: Run;
  userId: string;
  recorded: RecordedBlocks;
  stepRepeat: RepeatedStep;
  sectionId: string;
  sourceStepId: string;
  includeRedlines: boolean;
}): boolean => {
  // If run is already completed, drop this request.
  if (run.state === RUN_STATE.COMPLETED) {
    return false;
  }

  const sectionIndex = getSectionIndex(run, sectionId);
  if (
    run.sections[sectionIndex].steps.find((step) => step.id === stepRepeat.id)
  ) {
    // The step repeat with id ${stepRepeat.id} already exists in the document
    return false;
  }

  const stepRepeatUpdated: RepeatedStep = cloneDeep(stepRepeat);

  /*
   * If any repeats of the step already exist, update the new step
   * so that the `repeat_of` field points to the latest repeat
   */
  const latestRepeatedStep = getLastRepeat(
    run.sections[sectionIndex].steps as Array<RepeatedStep>,
    sourceStepId
  );

  let skippedAt = stepRepeat.repeated_at;
  if (latestRepeatedStep) {
    sourceStepId = latestRepeatedStep.id;
    stepRepeatUpdated.repeat_of = sourceStepId;

    // If the step is being skipped because other repeated steps are being added due to offline syncing, set skippedAt to the current timestamp
    skippedAt = new Date().toISOString();
  }

  /*
   * Get index of step to repeat. We need to do this in case an earlier step has
   * been repeated, which can shift all later steps in the array.
   *
   * Also, we need to check step settings.
   */
  const sourceStepIndex = run.sections[sectionIndex].steps.findIndex(
    (step) => step.id === sourceStepId
  );
  const sourceStep = run.sections[sectionIndex].steps[sourceStepIndex];
  if (!isRepeatStepSettingEnabled({ step: sourceStep, procedure: run })) {
    return false;
  }

  updateDocWithStepSkipped({
    run,
    userId,
    sectionId,
    stepId: sourceStepId,
    skippedAt,
    recorded,
    isSkippedInRepeat: true,
  });

  const runGraph = new ProcedureGraph(run);
  updateDependencyIds(runGraph, sourceStepId, stepRepeatUpdated.id);

  if (
    stepRepeatUpdated.duration &&
    typeof stepRepeatUpdated.duration === 'object'
  ) {
    stepRepeatUpdated.duration = {
      duration: '',
      started_at: '',
    };
  }

  if (stepRepeatUpdated.timer && typeof stepRepeatUpdated.timer === 'object') {
    stepRepeatUpdated.timer = {
      time_left: stepRepeatUpdated.timer.time_left,
      started_at: '',
      completed: false,
      time_remaining: '',
    };
  }

  delete run.sections[sectionIndex].steps[sourceStepIndex].conditionals;
  delete run.sections[sectionIndex].steps[sourceStepIndex].dependencies;

  // Insert the repeated step
  run.sections[sectionIndex].steps.splice(
    sourceStepIndex + 1,
    0,
    stepRepeatUpdated
  );

  if (
    includeRedlines &&
    stepRepeatUpdated.redlines &&
    stepRepeatUpdated.redlines.length > 0 &&
    stepRepeatUpdated.redlines.at(-1)?.pending
  ) {
    includeFullStepRedline({
      runDoc: run,
      userId,
      sectionId,
      stepId: stepRepeatUpdated.id,
      redlineId:
        getRedlineId(stepRepeatUpdated.redlines.at(-1) as RunStepRedline) ?? '',
      includedAt: stepRepeatUpdated.repeated_at,
    });
  }
  // Run doc was modified
  return true;
};

export const updateDocWithSectionRepeated = ({
  run,
  userId,
  recordedAllSectionSteps,
  sectionRepeatOptions,
  sectionId,
  includeRedlines,
}: {
  run: Run;
  userId: string;
  recordedAllSectionSteps: RecordedAllSectionSteps;
  sectionRepeatOptions: RepeatSectionOptions;
  sectionId: string;
  includeRedlines: boolean;
}) => {
  // If run is already completed, drop this request
  if (run.state === RUN_STATE.COMPLETED) {
    return false;
  }
  const { sectionRepeat, newToOldStepIds } = sectionRepeatOptions;
  const originalSectionId = sectionId;
  let sourceSectionIndex = getSectionIndex(run, sectionId);

  // The section repeat with id ${sectionRepeat.id} already exists in the document
  if (run.sections.find((section) => section.id === sectionRepeat.id)) {
    return false;
  }

  const sectionRepeatUpdated: RepeatedSection = cloneDeep(sectionRepeat);

  /*
   * If any repeats of the section already exist, update the new section
   * so that the `repeat_of` field points to the latest repeat
   */
  const latestRepeatedSection = getLastRepeat(run.sections, sectionId);

  let skippedAt = sectionRepeat.repeated_at;
  if (latestRepeatedSection) {
    sectionId = latestRepeatedSection.id;
    sectionRepeatUpdated.repeat_of = sectionId;

    // If the section is being skipped because other repeated sections are being added due to offline syncing, set skippedAt to the current timestamp
    skippedAt = new Date().toISOString();
  }

  // Again get the index of the section to repeat if the source section was updated.
  if (sectionId !== originalSectionId) {
    sourceSectionIndex = getSectionIndex(run, sectionId);
  }

  updateDocWithSectionSkipped({
    run,
    userId,
    sectionId,
    skippedAt,
    recordedAllSectionSteps,
    isRepeat: true,
  });

  const runGraph = new ProcedureGraph(run);
  runGraph.addSections([sectionRepeatUpdated]);

  updateDependencyIds(
    runGraph,
    newToOldStepIds[sectionRepeatUpdated.id],
    sectionRepeatUpdated.id
  );

  sectionRepeatUpdated.steps.forEach((step) => {
    if (step.duration && typeof step.duration === 'object') {
      step.duration = {
        duration: '',
        started_at: '',
      };
    }

    if (step.timer && typeof step.timer === 'object') {
      step.timer = {
        time_left: step.timer.time_left,
        started_at: '',
        completed: false,
        time_remaining: '',
      };
    }

    const oldStepId = newToOldStepIds[step.id];
    if (oldStepId) {
      updateDependencyIds(runGraph, oldStepId, step.id);
    }
  });

  delete run.sections[sourceSectionIndex].dependencies;
  run.sections[sourceSectionIndex].steps.forEach((step) => {
    delete step.conditionals;
    delete step.dependencies;
  });

  run.sections.splice(sourceSectionIndex + 1, 0, sectionRepeatUpdated);

  if (includeRedlines) {
    sectionRepeatUpdated.steps.forEach((step) => {
      if (
        step.redlines &&
        step.redlines.length > 0 &&
        step.redlines.at(-1)?.pending
      ) {
        includeFullStepRedline({
          runDoc: run,
          userId,
          sectionId: sectionRepeatUpdated.id,
          stepId: step.id,
          redlineId: getRedlineId(step.redlines.at(-1) as RunStepRedline) ?? '',
          includedAt: sectionRepeatUpdated.repeated_at,
        });
      }
    });
  }

  return true;
};

export const isRepeat = (repeatable: RepeatedSection | RepeatedStep) =>
  Boolean(repeatable.repeat_of);

// Returns all repeats of given id.
export const getRepeats = ({
  repeatables,
  id,
}: {
  repeatables: Array<RepeatedSection | RunSection | RepeatedStep | RunStep>;
  id: string;
}): Array<RepeatedSection | RunSection | RepeatedStep | RunStep> => {
  const repeatsArray: Array<
    RepeatedSection | RunSection | RepeatedStep | RunStep
  > = [];
  let parentId = id;

  repeatables.forEach((repeatable) => {
    if ((repeatable as RepeatedSection | RepeatedStep).repeat_of === parentId) {
      parentId = repeatable.id;

      repeatsArray.push(repeatable);
    }
  });

  return repeatsArray;
};

export const getRunWithoutRepeatsOrAddedSteps = (run: Run) => {
  const runCopy = lodash.cloneDeep(run);
  runCopy.sections = runCopy.sections
    .filter(
      (section): section is RunSection => !isRepeat(section as RepeatedSection)
    )
    .map((section) => {
      section.steps = section.steps.filter(isOriginalStep);
      return section;
    });
  return runCopy;
};

// Returns the original (without repeats or added steps) sectionIndex and stepIndex
export const findOriginalStepIndexPath = ({
  run,
  sectionId,
  stepId,
}: {
  run: Run;
  sectionId: string;
  stepId: string;
}) => {
  const originalSections = run.sections.filter(
    (section): section is RunSection => !isRepeat(section as RepeatedSection)
  );
  const sectionIndex = originalSections.findIndex(
    (section) => section.id === sectionId
  );

  if (sectionIndex === -1) {
    return null;
  }

  const originalSteps: Array<RunStep> =
    originalSections[sectionIndex].steps.filter(isOriginalStep);
  const stepIndex = originalSteps.findIndex((step) => step.id === stepId);

  if (stepIndex === -1) {
    return null;
  }

  return {
    sectionIndex,
    stepIndex,
  };
};

export const isOriginalStep = (step) =>
  !step.repeat_of && !step.created_during_run;

export const getLatestStepContext = ({
  sourceRun,
  originalSectionId,
  originalStepId,
}: {
  sourceRun: Run;
  originalSectionId: string;
  originalStepId: string;
}): {
  latestSectionId: string;
  latestStep: RunStep | RunAddedStep;
} => {
  const originalSection = sourceRun.sections.find(
    (section) => section.id === originalSectionId
  );
  const originalStep = originalSection?.steps.find(
    (step) => step.id === originalStepId
  );
  if (!originalSection || !originalStep) {
    throw new Error('Section or step not found');
  }

  if ((originalStep as RunAddedStep).created_during_run) {
    return {
      latestSectionId: originalSection.id,
      latestStep: originalStep,
    };
  }

  const matchingSectionRepeats = getRepeats({
    repeatables: sourceRun.sections,
    id: originalSection.id,
  }) as Array<RepeatedSection>;

  const latestRepeatedSection = matchingSectionRepeats.length
    ? matchingSectionRepeats[matchingSectionRepeats.length - 1]
    : originalSection;

  if (!latestRepeatedSection) {
    throw new Error(`section [${originalSection.id} not found`);
  }
  const originalStepIndex = findOriginalStepIndexPath({
    run: sourceRun,
    sectionId: originalSectionId,
    stepId: originalStepId,
  })?.stepIndex;
  if (lodash.isNil(originalStepIndex)) {
    throw new Error('Step not found.');
  }
  const firstStepInRepeatedSection =
    latestRepeatedSection.steps.filter(isOriginalStep)[originalStepIndex];

  const matchingStepRepeats = getRepeats({
    repeatables: latestRepeatedSection.steps,
    id: firstStepInRepeatedSection.id,
  }) as Array<RepeatedStep>;

  const latestStep = matchingStepRepeats.length
    ? matchingStepRepeats[matchingStepRepeats.length - 1]
    : firstStepInRepeatedSection;

  return {
    latestSectionId: latestRepeatedSection.id,
    latestStep,
  };
};

export const getBlockByContentId = (
  contentId: string,
  procedure?: Run,
  fieldIndex?: number
): RunStepBlock | null => {
  if (procedure) {
    procedure.sections.forEach((section) =>
      section.steps.forEach((step) =>
        step.content.forEach((content) => {
          if (content.id === contentId) {
            if (
              content.type === 'field_input_table' &&
              !lodash.isNil(fieldIndex)
            ) {
              return content.fields[fieldIndex];
            }
            return content;
          }
        })
      )
    );
  }

  return null;
};

const runUtil = {
  getStepState,
  isStepEnded,
  isStepStateEnded,
  RUN_STATE,
  ACTIVE_RUN_STATES,
  RUN_STATUS,
  STEP_STATE,
  newRunDoc,
  updateDocWithEndRun,
  updateDocWithRunReopened,
  updateDocWithAction,
  copyStepWithoutActiveContent,
  displaySectionKey,
  displaySectionStepKey,
  updateDocWithStepSignoffRevoked,
  updateDocWithSectionRepeated,
  updateDocWithSectionSkipped,
  updateDocWithStepRepeated,
  updateDocWithStepSkipped,
  updateDocWithRecorded,
  getSectionAndStepIndices,
  getRepeats,
  findOriginalStepIndexPath,
  getLatestStepContext,
  isRepeat,
  getBlockByContentId,
};

export default runUtil;

// Exported for legacy tests
export { _updateDocWithUnfinishedStepsSkipped };
export { _updateDocWithRunComment };
export { _updateDocWithRunStatus };
