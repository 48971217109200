import superlogin from './superlogin';
import { API_URL } from '../config';
import {
  ServerTag,
  UpsertTagParams,
} from 'shared/lib/types/api/settings/tags/models';
import ids from '../lib/idUtil';

class TagService {
  private teamId: string;

  constructor(teamId: string) {
    this.teamId = teamId;
  }

  async getTags(): Promise<ServerTag[]> {
    const url = `${API_URL}/teams/${this.teamId}/tags`;
    return superlogin
      .getHttp()
      .get(url)
      .then((resp) => resp.data.tags);
  }

  async upsertTag(tag: UpsertTagParams): Promise<string> {
    const tagId = tag.id || ids.generateLargeId();
    const { id, ...tagWithoutId } = tag;
    const url = `${API_URL}/teams/${this.teamId}/tags/${encodeURIComponent(
      tagId
    )}`;
    return superlogin
      .getHttp()
      .put(url, tagWithoutId)
      .then((resp) => resp.data);
  }

  async deleteTag(id: string): Promise<void> {
    const url = `${API_URL}/teams/${this.teamId}/tags/${encodeURIComponent(
      id
    )}`;
    return superlogin.getHttp().delete(url);
  }
}

export default TagService;
