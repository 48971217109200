import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { isEmptyValue } from 'shared/lib/text';
import { PartAutoNumbering } from 'shared/lib/types/couch/settings';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { PartBuildRecordedItem as RecordedItem } from 'shared/lib/types/views/procedures';
import Button, { BUTTON_TYPES } from '../../components/Button';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';
import { StyleVariant } from '../../elements/lib/fieldStyles';
import apm from '../../lib/apm';
import { StringSelectOption as SelectOption } from '../../lib/formik';
import { Item } from '../lib/inventoryUtil';
import { isPartRestricted } from '../lib/parts';
import { Location } from '../types';
import CheckedInItemLink from './CheckedInItemLink';
import ItemOverlay from './ItemOverlay';
import ItemQuantityInput from './ItemQuantityInput';
import LocationSelect from './LocationSelect';
import FullNumberInput from './Numbering/FullNumberInput';
import PartBadge from './PartBadge';
import RestrictedInfo, { RESTRICTED_TEXT } from './RestrictedInfo';
import { cellClass, getCellClass } from './util/tableUtil';

const showItemLink = (recorded?: RecordedItem): boolean => !!recorded?.item_id;

type FieldInputBuildItemProps = {
  part: Part | undefined;
  item: RecordedItem;
  recorded?: RecordedItem;
  isEnabled: boolean;
  autoNumbering?: PartAutoNumbering;
  onAddLotNumber?: (itemId?: string, prefix?: string) => Promise<void>;
  onClearLotNumber?: (itemId?: string) => Promise<void>;
  onRecordValuesChanged: (values: RecordedItem) => void;
  onRecordErrorsChanged: (errorObj: { [key: string]: string }) => void;
  teamId: string;
  canRemoveItem: boolean;
  onRemoveItem?: () => void;
  checkedOutItems?: Item[];
};

const FieldInputBuildItem = ({
  part,
  item,
  recorded,
  isEnabled,
  autoNumbering,
  onAddLotNumber,
  onClearLotNumber,
  onRecordValuesChanged,
  onRecordErrorsChanged,
  teamId,
  canRemoveItem,
  onRemoveItem,
  checkedOutItems,
}: FieldInputBuildItemProps) => {
  const prefix = useMemo(() => recorded?.prefix ?? item.prefix ?? '', [item.prefix, recorded?.prefix]);
  /**
   * This must be string (blank for unset).
   * 'null' is not allowed by the type of the 'value' prop below.
   * 'undefined' leads to subtle bugs.
   */
  const [lotNumber, setLotNumber] = useState(recorded?.lot ?? item.lot ?? '');
  const partRestricted = isPartRestricted(part);
  const [showItemOverlay, setShowItemOverlay] = useState(false);

  useEffect(() => {
    setLotNumber(recorded?.lot ?? item.lot ?? '');
  }, [item.lot, recorded?.lot]);

  useEffect(() => {
    if (part?.tracking === 'lot') {
      const lot = recorded?.lot || item.lot;
      if (isEmptyValue((lot ?? '').trim())) {
        onRecordErrorsChanged({ lot: 'Empty Lot #' });
      } else {
        onRecordErrorsChanged({});
      }
    }
    // including 'onRecordErrorsChanged' causes an infinite render cycle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [part?.tracking, recorded?.lot, item.lot]);

  const onChangePrefix = (option: SelectOption | null, itemId: string) => {
    const newPrefix = option?.value ?? '';
    if (newPrefix) {
      onAddLotNumber && onAddLotNumber(itemId, newPrefix).catch((err) => apm.captureError(err));
    } else {
      onClearLotNumber && onClearLotNumber(itemId).catch((err) => apm.captureError(err));
    }
  };

  const onChangeItemLot = (e: ChangeEvent<HTMLInputElement>) => {
    setShowItemOverlay(false);
    const lot = e.target.value;
    const values = {
      ...item,
      ...recorded,
      lot,
      prefix: '',
    };
    onRecordValuesChanged?.(values);
  };

  const onChangeAmount = (amount: number) => {
    const values = {
      ...item,
      ...recorded,
      amount,
    };
    onRecordValuesChanged?.(values);
  };

  const onChangeLocation = (location: Location) => {
    const values = {
      ...item,
      ...recorded,
      location_id: location ? location.id : '',
    };
    onRecordValuesChanged?.(values);
  };

  const onRevChange = (newRevLabel: string, newRevId?: string) => {
    const values = {
      ...item,
      ...recorded,
      revision: newRevLabel,
      revision_id: newRevId,
    };
    onRecordValuesChanged?.(values);
  };

  // For the run, initialize recorded values before signoff
  if (isEnabled && part?.tracking === 'none' && !recorded) {
    const values: RecordedItem = {
      ...item,
    };
    onRecordValuesChanged?.(values);
  }

  if (partRestricted) {
    return (
      <tr>
        <td className={`${cellClass} p-1`}>
          <RestrictedInfo text={RESTRICTED_TEXT} />
        </td>
        <td className={`${cellClass} p-1`}>
          <RestrictedInfo />
        </td>
        <td className={`${cellClass} p-1`}>
          <RestrictedInfo />
        </td>
        <td className={`${cellClass} p-1`}>
          <RestrictedInfo />
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td className={`${cellClass} p-1`}>
        <PartBadge
          inline={true}
          part={part}
          teamId={teamId}
          onRevChange={isEnabled && canRemoveItem ? onRevChange : undefined}
        />
      </td>
      <td className={getCellClass(!isEnabled)}>
        <div className="flex flex-row items-center">
          <ItemQuantityInput
            value={recorded?.amount ?? item.amount}
            allowDecimalValue={part?.allow_decimal_quantities === true}
            allowNull={false}
            onChange={(value) => onChangeAmount(value as number)}
            disabled={!isEnabled}
            min={0}
            placeholder="Quantity"
            textSize="sm"
            variant={StyleVariant.Grid}
          />
          {part?.units && (
            <div className="mx-1">
              <UnitDisplay unit={part.units} />
            </div>
          )}
        </div>
      </td>
      <td className={getCellClass(!isEnabled)}>
        {part?.tracking === 'lot' && (isEnabled || !showItemLink(recorded)) && (
          <div className="relative">
            <FullNumberInput
              type="lot_numbers"
              autoNumbering={autoNumbering}
              prefix={prefix}
              onPrefixChange={(option) => onChangePrefix(option, item.id)}
              value={lotNumber}
              onInputBlur={onChangeItemLot}
              onInputChange={(e) => setLotNumber(e.target.value)}
              entityId={item.id}
              onAdd={(itemId) => onAddLotNumber && onAddLotNumber(itemId, recorded?.prefix)}
              onClear={(itemId) => onClearLotNumber && onClearLotNumber(itemId)}
              disabled={!isEnabled}
              useFormik={false}
              onNumberInputClick={() => setShowItemOverlay(true)}
              showCaret={checkedOutItems && checkedOutItems.length > 0}
              variant={StyleVariant.Grid}
            />
            {showItemOverlay && (
              <ItemOverlay
                items={checkedOutItems}
                filter={lotNumber}
                onSelectItem={(item) => {
                  item.lot && setLotNumber(item.lot);
                  setShowItemOverlay(false);
                }}
              />
            )}
          </div>
        )}
        {!isEnabled && showItemLink(recorded) && (
          <CheckedInItemLink teamId={teamId} itemId={recorded?.item_id as string} trackingId={lotNumber || '--'} />
        )}
      </td>
      <td className={getCellClass(!isEnabled)}>
        {part && (
          <LocationSelect
            locationId={recorded?.location_id || ''}
            onChangeLocation={onChangeLocation}
            isDisabled={!isEnabled}
            variant={StyleVariant.Grid}
          />
        )}
      </td>
      {isEnabled && canRemoveItem && (
        <td className="w-5">
          <Button
            leadingIcon={faTimesCircle}
            iconTextColor="text-gray-400"
            type={BUTTON_TYPES.TERTIARY}
            isDisabled={!canRemoveItem}
            onClick={onRemoveItem}
          />
        </td>
      )}
    </tr>
  );
};

export default FieldInputBuildItem;
