import { Tag as GlobalTag } from 'shared/lib/types/api/settings/tags/models';
import PopupListWithSearch from '../../../elements/PopupListWithSearch';
import Button, { BUTTON_TYPES } from '../../Button';
import Tag from '../../Tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEFAULT_COLOR } from './TagModal';

interface TagSelectorProps {
  tagOptions: GlobalTag[];
  disallowedTags: string[];
  onCreateTag: (tagName: string) => void;
  onSelectTag: (tagId: string) => void;
  onEditTag: (tag: GlobalTag) => void;
}

const TagSelector = ({ tagOptions, disallowedTags, onCreateTag, onSelectTag, onEditTag }: TagSelectorProps) => {
  return (
    <PopupListWithSearch<GlobalTag>
      Components={{
        Trigger: () => (
          <Button type={BUTTON_TYPES.TERTIARY} leadingIcon="plus-circle">
            Add Tag
          </Button>
        ),
        ListItem: (option) => {
          const opt = option as { isCreateOption?: boolean; name: string };
          if (opt.isCreateOption && !tagOptions.some((tag) => tag.name.toLowerCase() === opt.name.toLowerCase())) {
            return (
              <div className="flex items-center justify-between w-full">
                <Tag id="new_tag" name={opt.name} color={DEFAULT_COLOR} />
              </div>
            );
          }

          const tag = option as GlobalTag;
          return (
            <div key={tag.id} className="group flex items-center justify-between w-full px-0">
              <div className="relative">
                <Tag id={tag.id} name={tag.name} color={tag.color || DEFAULT_COLOR} />
              </div>
              <button
                className="text-gray-500 opacity-0 group-hover:opacity-100"
                onClick={async (e) => {
                  e.stopPropagation();
                  await onEditTag(tag);
                }}
                aria-label="Edit Tag"
              >
                <FontAwesomeIcon icon="pencil" />
              </button>
            </div>
          );
        },
      }}
      options={tagOptions}
      onCreate={async (tagName) => onCreateTag(tagName)}
      disallowedTerms={disallowedTags}
      onSelect={async (option) => onSelectTag(option.id as string)}
      pt={{
        Trigger: {
          'aria-label': 'Add Tag',
        },
      }}
      maxItemLength={100}
    />
  );
};

export default TagSelector;
